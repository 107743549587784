.serviceCardContainer {
  /* border: solid brown; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 20px;
}
.serviceCardMain {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: 100%;
  margin: 10px;
}
.serviceCardWrapper {
  /* border: solid green; */
  width: 100%;
  position: relative;
  height: 400px;
  overflow: hidden;
}
.serviceCardImg {
  width: 100%;
  height: 100%;
  transition: 300ms ease;
}
.serviceCardCover {
  /* border: solid blue; */
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--customColor);
  padding: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.serviceCardTxtContainer {
  /* border: solid; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}
.serviceCardHead > h3 {
  font-size: 25px;
  color: white;
  text-align: center;
}
.serviceCardPara > p {
  font-size: 18px;
  text-align: justify;
  color: white;
  font-weight: 500;
}

.serviceCardBut > a > button {
  padding: 10px;
  font-size: 15px;
  border-radius: 10px;
  font-weight: bold;
  color: var(--customColor);
  border: none;
  background-color: white;
  cursor: pointer;
}
.serviceCardMain:hover .serviceCardCover {
  display: flex;
}
.serviceCardMain:hover .serviceCardImg {
  transform: scale(1.1);
}
.serviceGoto {
  /* border: solid rebeccapurple; */
  width: 80%;
  margin-top: -25px;
  background-color: white;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px black;
}
.serviceGoto > a {
  /* border: solid rebeccapurple; */
  width: 100%;
  color: var(--customColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 12px;
  font-size: 18px;
}
.serviceCardIcon {
  color: black;
}
.respContainer {
  /* border: solid green; */
  height: 300px;
  width: 100%;
  margin: 0px;
  position: relative;
  overflow: hidden;
  display: none;
}
.respServiceCardCover {
  /* border: solid blue; */
  height: 100%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.respServiceCardTxtContainer {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}
.respServiceCardHead > h3 {
  font-size: 25px;
  color: white;
  text-align: center;
}

.respServiceCardPara {
  font-weight: bold;
  color: white;
  text-align: center;
  width: 90%;
}
.respServiceCardPara > p {
  font-size: 20px;
  text-align: justify;
}
.respServiceCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 30px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .serviceCardContainer {
    /* border: solid brown; */
    padding: 10px;
  }
  .serviceCardMain {
    /* border: solid red; */
    min-width: 320px;
  }

  .serviceCardWrapper {
    /* border: solid green; */
    height: 200px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .respServiceCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 30px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .serviceGoto {
    width: 90%;
    height: 40px;
    background-color: white;
    margin-top: -20px;
  }
  .serviceGoto > .goTo1 > h3 {
    font-size: 130%;
  }
  .respContainer {
    display: flex;
  }
  .respServiceCardHead > h3 {
    font-size: 150%;
  }
  .respServiceCardPara > p {
    font-size: 90%;
  }
}
