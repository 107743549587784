.shopPageHead {
  /* border: solid; */
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.shopPageHead > h1 {
  color: var(--customColor);
  text-align: center;
}
.shopUnderLine {
    border: 2px solid var(--customColor);
  width: 150px
}
