.faceHeaderContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:20px;
  padding: 30px;
}
.faceHeaderContainer>h1{
    color: var(--customColor);
    width: 100%;
    text-align: center;
}
.faceHeaderContainer > h2 {
    color: var(--customColor);
    text-align: center;
    width: 100%;
    font-size: 30px;
  }
  .faceHeaderContainer > h3 {
    color: var(--customColor);
    text-align: start;
    width: 100%;
    font-size: 25px;
  }
  .faceHeaderContainer > h4 {
    color: var(--customColor);
    text-align: start;
    width: 100%;
    font-size: 25px;
  }
  .faceHeaderContainer > h5 {
    color: var(--customColor);
    text-align: start;
    width: 100%;
    font-size: 25px;
  }
  
  .faceHeaderContainer > ul > li {
    list-style: none;
    margin: 10px;
    font-size: 20px;
    text-align: justify;
  }
  .faceHeaderContainer > ul > li > h6 {
    font-size: 20px;
    text-align: justify;
    color: var(--customColor);
  }
  .faceHeaderContainer > ul > li > a {
    text-decoration: none;
    color: var(--customColor);
  }
  
  .faceHeaderContainer > ul > li > span {
    color: var(--customColor);
    font-weight: bold;
  }
  .faceHeaderContainer > p {
    text-align: justify;
    font-size: 20px;
  }
  .faceHeaderContainer > p > a {
    color: var(--customColor);
    text-decoration: none;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .faceHeaderContainer > h2 {
      text-align: center;
    }
    .faceHeaderContainer > h3 {
      text-align: center;
    }
    .faceHeaderContainer > h4 {
      text-align: center;
    }
    .faceHeaderContainer > h5 {
      text-align: center;
    }
  }