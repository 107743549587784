.franchisePageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.navbg {
  background-color: black;
  height: 100px;
}
.franchiseImgContainer {
  /* border: solid; */
  width: 100%;
  height: 400px;
  background-color: #060505;
  display: flex;
  justify-content: center;
  align-items: center;
}
.franchiseImgContainer > img {
  height: 100%;
  width: 100%;
}
.franchiseHeadContainer {
  /* border: solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.franchiseHeadContainer > h1 {
  font-size: 30px;
  color: var(--customColor);
  text-align: center;
}

.franchiseParaContainer {
  /* border: solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.franchiseParaContainer > p {
  font-size: 120%;
  text-align: center;
}
.franchiseFormContainer {
  /* border: solid green; */
  width: 550px;
  height: 70vh;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.645);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
}

.franchiseFormContainer > form {
  /* border: solid red; */
  width: 100%;
  height: 100%;
}
.franchiseFormContainer > form > fieldset {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.franchiseFormContainer > form > fieldset > legend {
  font-size: 150%;
  text-align: center;
  color: white;
  border: none;
  font-family: "Times New Roman", Times, serif;
}
.franchiseFormContainer > form > fieldset > table {
  /* border: solid yellow; */
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.franchiseFormContainer > form > fieldset > table > tr {
  /* border: solid purple; */
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px;
}
/* .franchiseFormContainer > form > fieldset > table > tr > label {
  color: white;
  font-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.franchiseFormContainer > form > fieldset > table > tr > td {
  /* border: solid blue; */
  width: 90%;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
}

.franchiseFormContainer > form > fieldset > table > tr > td > input {
  font-size: 20px;
  width: 80%;
  height: 90%;
  border: none;
  outline: none;
}
textarea::placeholder {
  font-size: 100%;
}

.franchiseFormContainer > form > fieldset > table > tr > textarea {
  font-size: 120%;
  border: none;
  border: none;
  outline: none;
  border-radius: 5px;
  width: 90%;
  font-size: 20px;
  padding: 10px;
}
.franchiseFormContainer > form > fieldset > table > button {
  height: 40px;
  width: 30%;
  border-radius: 20px;
  font-size: 130%;
  background-color: white;
  color: var(--customColor);
}
.franchiseFormContainer > form > fieldset > table > button:hover {
  background-color: var(--customRed);
  color: white;
}
.franchiseContactContainer {
  /* border: solid rebeccapurple; */
  width: 90%;
  height: 200px;
  font-size: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.franchiseCardContainer {
  /* border: solid; */
  height: 90%;
  width: 30%;
  border-radius: 10px;
  background-color: #1d2530;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.franchiseCardIcon {
  /* border: red solid; */
  background-color: #cc2228;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.franIcon {
  color: white;
}
/* .franchiseCardTxt > p {
  font-size: 100%;
  color: white;
  text-align: center;
} */
.franchiseCardTxt > p > a {
  /* border: solid; */
  font-size: 100%;
  color: white;
  text-align: center;
  text-decoration: none;
}
.franchiseCardTxt:hover #franchiseLink {
  text-decoration: underline;
}
.franchiseCardTxt > span {
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .franchisePageContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }
  .navbg {
    background-color: black;
    height: 100px;
  }
  .franchiseImgContainer {
    border: solid;
    width: 100%;
    height: 150px;
    background-color: #060505;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .franchiseImgContainer > img {
    height: 100%;
    width: 100%;
  }
  .franchiseHeadContainer {
    /* border: solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .franchiseHeadContainer > h2 {
    font-size: 150%;
    text-align: center;
  }

  .franchiseParaContainer {
    /* border: solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .franchiseParaContainer > p {
    font-size: 120%;
    text-align: center;
  }
  .franchiseFormContainer {
    /* border: solid green; */
    width: 90%;
    height: 70vh;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.645);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
  }

  .franchiseFormContainer > form {
    /* border: solid red; */
    width: 100%;
    height: 100%;
  }
  .franchiseFormContainer > form > fieldset {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .franchiseFormContainer > form > fieldset > legend {
    font-size: 150%;
    text-align: center;
    color: white;
    border: none;
    font-family: "Times New Roman", Times, serif;
  }
  .franchiseFormContainer > form > fieldset > table {
    /* border: solid yellow; */
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .franchiseFormContainer > form > fieldset > table > tr {
    /* border: solid purple; */
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 30px;
  }
  /* .franchiseFormContainer > form > fieldset > table > tr > label {
    color: white;
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .franchiseFormContainer > form > fieldset > table > tr > td {
    /* border: solid blue; */
    width: 120%;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
  }

  .franchiseFormContainer > form > fieldset > table > tr > td > input {
    font-size: 100%;
    width: 80%;
    height: 90%;
    border: none;
    outline: none;
  }
  textarea::placeholder {
    font-size: 100%;
  }

  .franchiseFormContainer > form > fieldset > table > tr > textarea {
    font-size: 120%;
    border: none;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 100%;
  }
  .franchiseFormContainer > form > fieldset > table > button {
    height: 40px;
    width: 40%;
    border-radius: 20px;
    font-size: 130%;
    background-color: white;
    color: var(--customColor);
  }
  .franchiseFormContainer > form > fieldset > table > button:hover {
    background-color: var(--customRed);
    color: white;
  }
  .franchiseContactContainer {
    /* border: solid rebeccapurple; */
    width: 100%;
    height: 360px;
    font-size: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }

  .franchiseCardContainer {
    /* border: solid; */
    height: 50%;
    width: 90%;
    border-radius: 10px;
    background-color: #1d2530;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .franchiseCardIcon {
    /* border: red solid; */
    background-color: #cc2228;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .franIcon {
    color: white;
  }
  /* .franchiseCardTxt > p {
    font-size: 100%;
    color: white;
    text-align: center;
  } */
  .franchiseCardTxt > p > a {
    /* border: solid; */
    font-size: 85%;
    color: white;
    text-align: center;
    text-decoration: none;
  }
  .franchiseCardTxt:hover #franchiseLink {
    text-decoration: underline;
  }
  .franchiseCardTxt > span {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .franchisePageContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }
  .navbg {
    background-color: black;
    height: 100px;
  }
  .franchiseImgContainer {
    border: solid;
    width: 100%;
    height: 200px;
    background-color: #060505;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5%;
  }
  .franchiseHeadContainer {
    /* border: solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .franchiseHeadContainer > h2 {
    font-size: 150%;
    text-align: center;
  }

  .franchiseParaContainer {
    /* border: solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .franchiseParaContainer > p {
    font-size: 120%;
    text-align: center;
  }
  .franchiseFormContainer {
    /* border: solid green; */
    width: 50%;
    height: 70vh;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.645);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
  }

  .franchiseFormContainer > form {
    /* border: solid red; */
    width: 100%;
    height: 100%;
  }
  .franchiseFormContainer > form > fieldset {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .franchiseFormContainer > form > fieldset > legend {
    font-size: 150%;
    text-align: center;
    color: white;
    border: none;
    font-family: "Times New Roman", Times, serif;
  }
  .franchiseFormContainer > form > fieldset > table {
    /* border: solid yellow; */
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .franchiseFormContainer > form > fieldset > table > tr {
    /* border: solid purple; */
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 30px;
  }
  /* .franchiseFormContainer > form > fieldset > table > tr > label {
    color: white;
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .franchiseFormContainer > form > fieldset > table > tr > td {
    /* border: solid blue; */
    width: 120%;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
  }

  .franchiseFormContainer > form > fieldset > table > tr > td > input {
    font-size: 100%;
    width: 80%;
    height: 90%;
    border: none;
    outline: none;
  }
  textarea::placeholder {
    font-size: 100%;
  }

  .franchiseFormContainer > form > fieldset > table > tr > textarea {
    font-size: 120%;
    border: none;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 100%;
  }
  .franchiseFormContainer > form > fieldset > table > button {
    height: 40px;
    width: 40%;
    border-radius: 20px;
    font-size: 130%;
    background-color: white;
    color: var(--customColor);
  }
  .franchiseFormContainer > form > fieldset > table > button:hover {
    background-color: var(--customRed);
    color: white;
  }
  .franchiseContactContainer {
    /* border: solid rebeccapurple; */
    width: 90%;
    height: 360px;
    font-size: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .franchiseCardContainer {
    /* border: solid; */
    height: 50%;
    width: 90%;
    border-radius: 10px;
    background-color: #1d2530;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .franchiseCardIcon {
    /* border: red solid; */
    background-color: #cc2228;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .franIcon {
    color: white;
  }
  /* .franchiseCardTxt > p {
    font-size: 100%;
    color: white;
    text-align: center;
  } */
  .franchiseCardTxt > p > a {
    /* border: solid; */
    font-size: 85%;
    color: white;
    text-align: center;
    text-decoration: none;
  }
  .franchiseCardTxt:hover #franchiseLink {
    text-decoration: underline;
  }
  .franchiseCardTxt > span {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1200px) and (min-width: 990px) {
  .franchisePageContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .navbg {
    background-color: black;
    height: 100px;
  }
  .franchiseImgContainer {
    /* border: solid; */
    width: 100%;
    height: 400px;
    background-color: #060505;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -31px;
  }
  .franchiseImgContainer > img {
    height: 100%;
    width: 80%;
  }
  .franchiseHeadContainer {
    /* border: solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .franchiseParaContainer {
    /* border: solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .franchiseParaContainer > p {
    font-size: 120%;
    text-align: center;
  }
  .franchiseFormContainer {
    /* border: solid green; */
    width: 400px;
    height: 70vh;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.645);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
  }

  .franchiseFormContainer > form {
    /* border: solid red; */
    width: 100%;
    height: 100%;
  }
  .franchiseFormContainer > form > fieldset {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .franchiseFormContainer > form > fieldset > legend {
    font-size: 150%;
    text-align: center;
    color: white;
    border: none;
    font-family: "Times New Roman", Times, serif;
  }
  .franchiseFormContainer > form > fieldset > table {
    /* border: solid yellow; */
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .franchiseFormContainer > form > fieldset > table > tr {
    /* border: solid purple; */
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
  }
  /* .franchiseFormContainer > form > fieldset > table > tr > label {
    color: white;
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .franchiseFormContainer > form > fieldset > table > tr > td {
    /* border: solid blue; */
    width: 90%;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
  }

  .franchiseFormContainer > form > fieldset > table > tr > td > input {
    font-size: 100%;
    width: 80%;
    height: 90%;
    border: none;
    outline: none;
  }
  textarea::placeholder {
    font-size: 100%;
  }

  .franchiseFormContainer > form > fieldset > table > tr > textarea {
    font-size: 120%;
    border: none;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 90%;
  }
  .franchiseFormContainer > form > fieldset > table > button {
    height: 40px;
    width: 30%;
    border-radius: 20px;
    font-size: 130%;
    background-color: white;
    color: var(--customColor);
  }
  .franchiseFormContainer > form > fieldset > table > button:hover {
    background-color: var(--customRed);
    color: white;
  }
  .franchiseContactContainer {
    /* border: solid rebeccapurple; */
    width: 90%;
    height: 200px;
    font-size: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .franchiseCardContainer {
    border: solid;
    height: 90%;
    width: 36%;
    border-radius: 10px;
    background-color: #1d2530;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .franchiseCardIcon {
    /* border: red solid; */
    background-color: #cc2228;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .franIcon {
    color: white;
  }
  /* .franchiseCardTxt > p {
    font-size: 100%;
    color: white;
    text-align: center;
  } */
  .franchiseCardTxt > p > a {
    /* border: solid; */
    font-size: 100%;
    color: white;
    text-align: center;
    text-decoration: none;
  }
  .franchiseCardTxt:hover #franchiseLink {
    text-decoration: underline;
  }
  .franchiseCardTxt > span {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .navbg {
    background-color: black;
    height: 120px;
  }
}
