:root {
  --customBlack: #141414;
}

.membershipImgContainer {
  /* border: solid blue; */
  width: 100%;
  height: 100%;
  background-color: var(--customBlack);
  display: flex;
  justify-content: center;
  align-items: center;
}

.membershipHeadContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.membershipHeadContainer > h1 {
  font-size: 30px;
  color: var(--customColor);
}
.membershipUnderline {
  border: 2px var(--customColor) solid;
  width: 8%;
}
.membershipParaContainer {
  /* border: solid; */
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.membershipParaContainer > p {
  font-size: 20px;
  margin: 10px;
  text-align: justify;
  width: 80%;
}
.membershipPriceContainer {
  /* border: solid; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.membershipPriceContent {
  /* Existing styles */
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  padding: 20px;
  background-color: #282c31;
  border-radius: 20px;
  position: relative; /* Needed for positioning the pseudo-element */
  overflow: hidden; /* Ensures content stays within rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Regular shadow */
}

.membershipPriceContent::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  transform: translateX(-100%);
  animation: shadow-shine 3s infinite;
  pointer-events: none; /* Ensures this shine does not affect interactions */
  border-radius: 50%; /* Makes the shine rounded */
}

@keyframes shadow-shine {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.membershipPriceContent > div {
  border: 1px solid white;
  padding: 10px;
  width: 200px;
  text-align: center;
  color: white;
  border-radius: 10px;
}
.membershipPriceItem1 {
  border: 1px solid var(--customColor);
  background-color: white;
  color: var(--customColor);
}
.membershipPriceItem1 > h3 {
  color: var(--customColor);
}
.membershipPriceItem2,
.membershipPriceItem3,
.membershipPriceItem4,
.membershipPriceItem5 {
  background-color: var(--customColor);
  color: white;
}
.membershipPriceItem6,
.membershipPriceItem11,
.membershipPriceItem16,
.membershipPriceItem21,
.membershipPriceItem26,
.membershipPriceItem31 {
  background-color: var(--customColor);
  color: white;
}
.membershipPriceItem7,
.membershipPriceItem9,
.membershipPriceItem13,
.membershipPriceItem15,
.membershipPriceItem17,
.membershipPriceItem19,
.membershipPriceItem25,
.membershipPriceItem23,
.membershipPriceItem27,
.membershipPriceItem29,
.membershipPriceItem33,
.membershipPriceItem35 {
  background-color: rgb(29, 29, 29);
  color: white;
}
.membershipPriceItem8,
.membershipPriceItem10,
.membershipPriceItem12,
.membershipPriceItem14,
.membershipPriceItem18,
.membershipPriceItem20,
.membershipPriceItem22,
.membershipPriceItem24,
.membershipPriceItem28,
.membershipPriceItem30,
.membershipPriceItem32,
.membershipPriceItem34 {
  color: white;
  background-color: #282c31;
}
.membershipPriceItem10 > h3,
.membershipPriceItem15 > h3,
.membershipPriceItem20 > h3,
.membershipPriceItem25 > h3,
.membershipPriceItem30 > h3,
.membershipPriceItem35 > h3 {
  color: white;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .membershipImgContainer {
    /* border: solid blue; */
    width: 100%;
    /* height: 22vh; */
    background-color: var(--customBlack);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .membershipImgContainer > img {
    width: 100%;
  }
  .membershipHeadContainer > h2 {
    font-size: 30px;
  }

  .membershipParaContainer > p {
    font-size: 110%;
    margin: 15px;
    text-align: justify;
  }
  .membershipParaContainer {
    /* border: solid red; */
    margin-top: 5%;
    height: 100%;
  }

  .membershipPriceContent {
    /* border: solid blue; */
    width: 800px;
    height: 100%;
    overflow-x: scroll;
  }
}
@media screen and (max-width: 990px) and (min-width: 768px) {
  .membershipImgContainer {
    /* border: solid blue; */
    width: 100%;
    height: 22vh;
    background-color: var(--customBlack);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12%;
  }
  .membershipImgContainer > img {
    width: 100%;
  }
  .membershipHeadContainer {
    margin-top: 15%;
  }
  .membershipHeadContainer > h2 {
    font-size: 30px;
  }

  .membershipParaContainer > p {
    font-size: 110%;
    margin: 15px;
    text-align: start;
  }
  .membershipParaContainer {
    /* border: solid; */
    margin-top: 5%;
    height: 80vh;
  }
  .membershipParaContainer {
    /* border: solid red; */
    margin-top: 5%;
    height: 100%;
  }
  .membershipPriceContent {
    /* border: solid blue; */
    width: 800px;
    height: 100%;
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1200px) and (min-width: 990px) {
  .navbg {
    background-color: black;
    height: 100px;
    width: 100%;
  }
  .membershipImgContainer {
    /* border: solid blue; */
    width: 100%;
    /* height: 42vh; */
    background-color: var(--customBlack);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .membershipHeadContainer {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
  }
  .membershipHeadContainer > h2 {
    font-size: 30px;
  }
  .membershipUnderline {
    border: 2px red solid;
    width: 15%;
  }
  .membershipParaContainer {
    /* border: solid; */
    margin-top: 5%;
    height: 50vh;
  }
  .membershipParaContainer > p {
    font-size: 20px;
    margin: 10px;
    text-align: center;
  }
  .membershipPriceContainer {
    /* border: solid; */
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
  .membershipCardContainer {
    /* border: solid red; */
    height: 350px;
    width: 40%;
    border-radius: 10px;
    background-color: #cc2228;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5%;
  }
  .membershipCardTxt {
    border-bottom: 1px solid white;
    width: 80%;
    height: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .membershipCardTxt > h2,
  h4 {
    color: white;
  }
  .membershipPrice {
    border-bottom: 1px solid white;
    width: 80%;
    height: 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .membershipPrice > h3 {
    color: white;
  }
  .rupeeicon {
    color: white;
  }
}
