.navBg {
  background-color: black;
  height: 14vh;
  width: 100%;
  display: none;
}

@media screen and (max-width: 600px) {
  .navBg {
    background-color: black;
    height: 12vh;
    width: 100%;
    display: none;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .navBg {
    background-color: black;
    height: 12vh;
    width: 100%;
    display: none;
  }
}
@media screen and (max-width: 1200px) and (min-width: 990px) {
  .navBg {
    background-color: black;
    height: 14vh;
    width: 100%;
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .navBg {
    background-color: black;
    height: 14vh;
    display: none;
  }
}
