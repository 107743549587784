.lavellelLocPageContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}
.lavellelLocPageContent {
  /* border: solid green; */
  width: 100%;
}
