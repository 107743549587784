.QAPageContainer {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.QAPageHeader {
  /* border: solid blue; */
  width: 100%;
  height: 600px;
}
.QAPageHeader > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.QAPageWrapper {
  /* border: solid pink; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.QAPageMain {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  /* overflow-y:scroll ; */
}
.QAPageCard {
  /* border: solid brown; */
  width: 900px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 5px black;
  border-radius: 5px;
  padding: 25px;
  position: relative;
}
.QAPageCardLine {
  /* border: 2px solid red; */
  height: 250px;
  width: 0%;
  position: absolute;
  left: 0;
}
.QAPageCardCover {
  /* border: solid yellow; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.QAStatistics {
  width: 30%;
  height: 100%;
  /* border: solid teal; */
  padding: 10px;
}
.QAPageCardCover-content1 {
  height: 35%;
  width: 100%;
  /* border: solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.QAPageCardCover-content3 {
  height: 15%;
  width: 100%;
  /* border: solid black; */
}
.QAPageCardCover-item1 {
  border: 2px solid var(--customColor);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.QAPageCardCover-item1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.QAPageCardCover-item2 {
  width: 90%;
  height: 100%;
}
.QAPageCardCover-innerItem1 {
  height: 30%;
  width: 100%;
  /* border: solid pink; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  padding-left: 10px;
}
.QAPageCardCover-innerItem2 {
  height: 70%;
  width: 100%;
  /* border: solid blue; */
}
.QAPageCardCover-block1 > h3 {
  color: var(--customColor);
}
.QAPageCardCover-block2 {
  padding: 2px;
  border-radius: 5px;
  color: white;
  background-color: var(--customColor);
}
.QAPageCardCover-block3 {
  font-weight: 500;
}
.QAPageCardCover-block3 > span {
  color: var(--customColor);
}
.QAPageCardCover-innerItem2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.QAPageCardCover-content2 {
  height: 50%;
  width: 100%;
  /* border: solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.QAPageCardCover-content2 > p {
  font-size: 18px;
  font-weight: 500;
  text-align: justify;
}
#lineId {
  border: 2px solid black;
  height: 250px;
  width: 0%;
  position: absolute;
  left: 0;
}
.QAPageCardCover-innerItem2>h2>a{
  color: black;text-decoration: none;
}
/* ------------------------search tab--------------------------- */

.QAPageMainSearch {
  /* border: solid red; */
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  background-image: url("./../../Images/QandA.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.QAPageMainSearchCover {
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(26, 26, 26, 0.138);
}
.QAPageMainSearchWrapper {
  /* border: solid var(--customColor); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  height: 60px;
  border-radius: 50px;
  padding: 5px;
  overflow: hidden;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.395);
}
.QAPageMainSearchWrapper > input {
  padding: 10px;
  width: 70%;
  font-size: 18px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(94, 94, 94);
}
.downArrowBtn {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(255, 255, 255, 0.611);
}
.magBtn {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(255, 255, 255, 0.611);
  cursor: pointer;
}
.searchIcon {
  font-size: 15px;
  color: var(--customColor);
}
.QAPageMainSearchWrapper:hover .searchIcon {
  animation: search 1s ease-in-out infinite;
}
@keyframes search {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}
.QAPageMainContentWrapper {
  /* border: solid green; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 30px;
}
.QAPageMainContent {
  /* border: solid red; */
}
.QAPageMenuContent {
  /* border: solid blue; */
  width: 500px;
  height: 100%;
  padding: 10px;
  box-shadow: 0 0 5px black;
}
.QAPageMenuContent > h3 {
  border-bottom: 2px solid grey;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
