.textReviewContainer {
  /* border: solid; */
  width: 100%;
  height: 9vh;
  /* margin-top: 5%; */
  background-color:var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.textReviewContent {
  /* border: solid green; */
  height: 100%;
  width: 35%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.reviewStar {
  color: gold;
}
.textReviewContent > p {
  font-size: 120%;
  font-weight: bold;
  color: white;
}
@media screen and (max-width: 600px) {
  .textReviewContent {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .textReviewContent > p {
    font-size: 90%;
    width: 200px;
    font-weight: bold;
    color: white;
    text-align: center;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .textReviewContent {
    /* border: solid green; */
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
@media screen and (max-width: 1200px) and (min-width: 990px) {
  .textReviewContent {
    /* border: solid green; */
    height: 100%;
    width: 55%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .reviewStarContainer {
    /* border: solid; */
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .reviewStar {
    /* border: solid; */
    color: gold;
    font-size: 20px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .textReviewContent {
    /* border: solid green; */
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .reviewStarContainer {
    /* border: solid; */
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .reviewStar {
    /* border: solid; */
    color: gold;
    font-size: 20px;
  }
}
