

.beautyImgContainer {
  /* border: solid red; */
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
/* .servicePageImgContainer > img {
    height: 80%;
    width: 90%;
    position: relative;
    z-index: 1;
  } */
.imgCover {
  width: 90%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.595);
  position: relative;
  z-index: 1;
  /* background: url("https://videocdn.cdnpk.net/joy/content/video/free/video0460/large_preview/_import_60d2f41e3bcdc1.08424385.mp4");
    background-repeat: no-repeat;
    background-size: cover; */
}
.imgCover > video {
  /* border: red solid; */
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.cover {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.595);
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.overCoverTxt {
  /* border: solid; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.overCoverTxt > h2 {
  font-size: 50px;
  font-weight: lighter;
  text-align: center;
  color: white;
}
.coverUnderline {
  border: 2px solid;
  width: 100px;
  color: var(--customColor);
}
.overCoverTxt > p {
  /* border: solid; */
  text-align: center;
  width: 95%;
  font-size: 20px;
}

@media screen and (min-width:320px) and (max-width:700px) {
  .imgCover > video {
    /* border: red solid; */
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: -200px 0px;
  }
}
