.hairSalonInMeadowsHead {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
  }
  .hairSalonInMeadowsHead > h1 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
  }
  .hairSalonInMeadowsHead > h2 {
    color: var(--customColor);
    text-align: center;
    width: 100%;
    font-size: 25px;
  }
  .hairSalonInMeadowsHead > h3 {
    color: var(--customColor);
    text-align: start;
    width: 100%;
    font-size: 25px;
  }
  .hairSalonInMeadowsHead > h4 {
    color: var(--customColor);
    text-align: start;
    width: 100%;
    font-size: 25px;
  }
  .hairSalonInMeadowsHead > h5 {
    color: var(--customColor);
    text-align: start;
    width: 100%;
    font-size: 25px;
  }
  
  .hairSalonInMeadowsHead > ul > li {
    list-style: none;
    margin: 10px;
    font-size: 20px;
    text-align: justify;
  }
  .hairSalonInMeadowsHead > ul > li > h6 {
    font-size: 20px;
    text-align: justify;
    color: var(--customColor);
  }
  .hairSalonInMeadowsHead > ul > li > a {
    text-decoration: none;
    color: var(--customColor);
  }
  
  .hairSalonInMeadowsHead > ul > li > span {
    color: var(--customColor);
    font-weight: bold;
  }
  .hairSalonInMeadowsHead > p {
    text-align: justify;
    font-size: 20px;
  }
  .hairSalonInMeadowsHead > p > a {
    color: var(--customColor);
    text-decoration: none;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .hairSalonInMeadowsHead > h1 {
      font-size: 25px;
      text-align: center;
    }
    .hairSalonInMeadowsHead > h2 {
      text-align: center;
      font-size: 20px;
    }
    .hairSalonInMeadowsHead > h3 {
      text-align: center;
      font-size: 20px;
    }
    .hairSalonInMeadowsHead > h4 {
      text-align: center;
      font-size: 20px;
    }
    .hairSalonInMeadowsHead > h5 {
      text-align: center;
      font-size: 20px;
    }
  }
  