.hairSalonNearMeRead {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  padding: 20px;
}

.hairSalonNearMeReadHead {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.hairSalonNearMeReadHead > button {
  border: none;
  background-color: white;
  cursor: pointer;
}
.hairSalonNearMeReadDisplay {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: none;
  cursor: default;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
.openBut {
  text-decoration: underline;
  font-weight: 400;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .hairSalonNearMeRead {
    /* border: solid red; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    text-align: justify;
  }

  .hairSalonNearMeReadDisplay {
    height: 100%;
  }
}
