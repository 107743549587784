.BridalMakeUpCustomerContainer {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 100%;
  }
  .BridalMakeUpCustomerContainer > h5 {
    text-align: center;
    width: 100%;
    font-size: 25px;
    color: var(--customColor);
  }
  .BridalMakeUpCustomerContainer > p {
    font-size: 20px;
    text-align: justify;
  }
  .BridalMakeUpCustomerContainer > p > a {
    text-decoration: none;
    color: var(--customColor);
  }
  .BridalMakeUpCustomerContent {
    /* border: solid blue; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }
  .BridalMakeUpCustomerContent > p {
    font-size: 20px;
    text-align: justify;
    width: 70%;
    line-height: 35px;
  }
  .BridalMakeUpCustomerContent > p > span {
    color: var(--customColor);
    font-weight: bold;
  }
  .quote {
    font-size: 30px;
  }
  