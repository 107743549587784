

.facialImgContainer {
  /* border: solid red; */
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
/* .servicePageImgContainer > img {
    height: 80%;
    width: 90%;
    position: relative;
    z-index: 1;
  } */
.imgCover {
  width: 90%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.595);
  position: relative;
  z-index: 1;
  /* background: url("https://videocdn.cdnpk.net/joy/content/video/free/video0460/large_preview/_import_60d2f41e3bcdc1.08424385.mp4");
    background-repeat: no-repeat;
    background-size: cover; */
}
.imgCover > video {
  /* border: red solid; */
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.cover {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.595);
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.overCoverTxt {
  /* border: solid; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.overCoverTxt > h1 {
  font-size: 50px;
  font-weight: lighter;
  color: white;
}
.coverUnderline {
  border: 2px solid;
  width: 100px;
  color: var(--customColor);
}
.overCoverTxt > p {
  /* border: solid; */
  text-align: center;
  width: 95%;
  font-size: 20px;
}
@media screen and (max-width: 600px)  {
  .facialImgContainer {
    /* border: solid red; */
    height: 70vh;
    width: 100%;
  }
  .overCoverTxt > h1 {
    text-align: center;
  }
  .overCoverTxt > p {
    width: 100%;
    letter-spacing: 1px;
    font-size: 80%;
  }

 
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .facialImgContainer {
    /* border: solid red; */
    height: 70vh;
    width: 100%;
  }
  .overCoverTxt > p {
    width: 100%;
    letter-spacing: 1px;
    font-size: 80%;
  }
}
