.hairTreatmentLandingProgram {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  background-color: white;
}
.hairTreatmentHeadProgram {
  /* border: solid green; */
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.hairTreatmentHeadProgram > h2 {
  font-size: 50px;
  font-weight: 400;
  color: var(--customColor);
}
.hairTreatmentHeadProgram > p {
  font-size: 20px;
}
.hairTreatmentMainProgram {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  padding: 20px;
}
.hairTreatmentProgramCard {
  /* border: solid brown; */
  width: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.hairTreatmentProgramCardContent1 {
  border: 5px solid black;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  transition: 300ms ease-in-out;
}
.hairTreatmentProgramCardContent1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  position: relative;
  background-color: black;
}
.hairTreatmentProgramCardCover {
  /* border: solid red; */
  background-color: rgba(0, 0, 0, 0.619);
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  transform: translateY(100%);
  transition: 300ms ease-in-out;
}
.hairTreatmentProgramCardCover > h4 {
  font-size: 30px;
  color: var(--customColor);
}
.hairTreatmentProgramCardCover > p {
  font-size: 20px;
  color: white;
}
.hairTreatmentProgramCardContent1:hover {
  border: 5px solid #2e2e30;
}
.hairTreatmentProgramCardContent1:hover .hairTreatmentProgramCardCover {
  transform: translateX(0);
}

.hairTreatmentProgramCardCover:hover .hairTreatmentProgramCardCover > h4 {
  transform: scale(1.2);
}
.hairTreatmentProgramCardContent2 > h3 {
  font-size: 30px;
  color: var(--customColor);
  font-weight: 400;
}
.hairTreatmentProgramCardContent3 {
  /* border: solid blue; */
  width: 60%;
}
.hairTreatmentProgramCardContent3 > p {
  width: 100%;
  text-align: center;
  font-size: 15px;
}
.hairTreatmentMainProgram > a {
  text-decoration: none;
}
.hairTreatmentMainProgram > a > button {
  height: 50px;
  padding: 20px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  background-color: var(--customColor);
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.hairTreatmentMainProgram > a > button:hover {
  border: solid var(--customColor);
  color: var(--customColor);
  background-color: white;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hairTreatmentLandingProgram {
    /* border: solid red; */
    padding: 10px;
  }
  .hairTreatmentMainProgram {
    /* border: solid blue; */
    flex-direction: column;
    padding: 0px;
  }
  .hairTreatmentProgramCard {
    /* border: solid brown; */
    width: 100%;
    min-width: 300px;
    padding: 20px;
  }
  .hairTreatmentProgramCardContent1 {
    width: 200px;
    height: 200px;
  }
}
