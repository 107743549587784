.hairReadContainer {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}
.hairReadHeadContainer {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.hairReadHeadContainer > button {
  border: none;
  background-color: white;
  cursor: pointer;
  padding: 30px;
}
.hairReadHeadContainer > button > h6 {
  font-size: 20px;
  text-decoration: underline;
  font-weight: 400;
  color: var(--customColor);
}
.hairReadContainer > header > h1 {
  margin: 20px;
  font-size: 40px;
  color: var(--customColor);
  display: block;
  font-weight: 400;
  text-align: center;
}
.hairReadHeadContainer > ul {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hairReadHeadContainer > ul > li {
  /* border: solid green; */
  width: 90%;
  font-size: 20px;
  list-style: none;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.hairReadHeadContainer > ul > li > h4 {
  font-size: 20px;
  font-weight: 400;
  color: var(--customColor);
}
.hairReadHeadContainer > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.hairReadDisplayContainer {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: none;
  cursor: default;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hairReadDisplayContainer > h3 {
  font-size: 25px;
  color: var(--customColor);

}
.hairReadDisplayContainer > ul > li > h4 {
  font-size: 20px;
  font-weight: 400;
  color: var(--customColor);

}
.hairReadDisplayContainer > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.hairReadDisplayContainer > ul {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hairReadDisplayContainer > ul > li {
  /* border: solid blue; */
  width: 90%;
  font-size: 20px;
  list-style: none;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.hairReadDisplayContainer > ul > li > a {
  color: var(--customColor);
  text-decoration: none;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hairReadContainer {
    /* border: solid red; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    text-align: justify;
  }
  .hairReadHeadContainer > h1 {
    text-align: center;
  }
  .hairReadDisplayContainer {
    height: 100%;
  }
  .readmehead2 > h3 {
    font-size: 120%;
    color: black;
  }

  .hairReadDisplayContainer ul > li {
    /* border: solid green; */
    margin: 5%;
    font-size: 120%;
  }
}
