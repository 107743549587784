.gelNailArtTestimonialContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  width: 100%;
}
.gelNailArtTestimonialContainer > h5 {
  text-align: center;
  width: 100%;
  font-size: 25px;
  color: var(--customColor);
}
.gelNailArtTestimonialContainer > p {
  font-size: 20px;
  text-align: justify;
}
.gelNailArtTestimonialContainer > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.gelNailArtTestimonialContent {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.gelNailArtTestimonialContent > p {
  font-size: 20px;
  text-align: justify;
  width: 70%;
  line-height: 35px;
}
#gelNailArtTestimonialContent {
  flex-direction: column;
}
#gelNailArtTestimonialContent > p {
  /* border: solid; */
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 5px rgb(171, 171, 171);
}
.gelNailArtTestimonialContent > p > span {
  color: var(--customColor);
  font-weight: bold;
}
.quote {
  font-size: 30px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .gelNailArtTestimonialContent {
    /* border: solid blue; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }
}
