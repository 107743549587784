.contactMainContainer {
  /* border: solid; */
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactMainContent {
  /* border: solid red; */
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#contactMainContent1 {
  background-color: #cc2228;
}
.contactsContainer {
  /* border: solid green; */
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.contacts1,
.contacts2,
.contacts3 {
  /* border: solid blue; */
  width: 90%;
  height: 22vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.contacts1 > h1 {
  color: white;
}
.contacts1 > p {
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-size: 120%;
}
.contacts1 > p > a {
  color: white;
  text-decoration: none;
}
.contacts2 > h2 {
  color: white;
}
.contacts2 > p {
  color: white;
  font-size: 120%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.contacts2 > p > a {
  color: white;
  text-decoration: none;
}
.contacts3 > h2 {
  color: white;
}
.contacts3 > p {
  color: white;
  font-size: 120%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.contacts3 > p > a {
  color: white;
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .contactMainContainer {
    /* border: solid; */
    width: 100%;
    height: 160vh;
    flex-direction: column;
  }
  .contactMainContent {
    /* border: solid red; */
    height: 100%;
    width: 100%;
  }
  .contactsContainer {
    /* border: solid green; */
    width: 100%;
    height: 80%;
    gap: 50px;
  }
  .contacts1 > h2,
  .contacts2 > h2,
  .contacts3 > h2 {
    font-size: 150%;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .contactMainContainer {
    /* border: solid; */
    width: 100%;
    height: 160vh;
    flex-direction: column;
  }
  .contactMainContent {
    /* border: solid red; */
    height: 100%;
    width: 100%;
  }
  .contactsContainer {
    /* border: solid green; */
    width: 100%;
    height: 80%;
    gap: 50px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 990px) {
  .contactMainContainer {
    /* border: solid; */
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contactMainContent {
    /* border: solid red; */
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
