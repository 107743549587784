.hairCutBenefitsContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
  }
  .hairCutBenefitsContainer > h2 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
    font-size: 25px;
  }
  .hairCutBenefitsContainer > h3 {
    color: var(--customColor);
    width: 100%;
    text-align: start;
    font-size: 25px;
  }
  .hairCutBenefitsContainer > p {
    width: 100%;
    font-size: 20px;
    text-align: justify;
  }
  .hairCutBenefitsContainer > p > a {
    color: var(--customColor);
    text-decoration: none;
  }
  .hairCutBenefitsContainer > li > h6 {
    color: var(--customColor);
    font-size: 20px;
  }
  
  .hairCutBenefitsContainer > li {
    /* border: solid; */
    width: 100%;
    font-size: 20px;
    text-align: start;
    list-style: none;
  }
  .hairCutBenefitsContainer > li > span {
    /* border: solid; */
    color: var(--customColor);
  }
  .hairCutBenefitsContainer > li > a {
    text-decoration: none;
    color: var(--customColor);
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .hairCutBenefitsContainer > h2 {
      color: var(--customColor);
      width: 100%;
      text-align: center;
      font-size: 20px;
    }
    .hairCutBenefitsContainer > h3 {
      color: var(--customColor);
      width: 100%;
      text-align: start;
      font-size: 20px;
    }
  }