.hairBotoxHow {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  padding: 20px;
}
.hairBotoxHow > h2 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
  font-size: 30px;
}
.hairBotoxHow > h3 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
  font-size: 25px;
}
.hairBotoxHow > p {
  width: 100%;
  font-size: 20px;
  text-align: justify;
}
.hairBotoxHow > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.hairBotoxHow > li > h6 {
  color: var(--customColor);
  font-size: 20px;
}

.hairBotoxHow > li {
  /* border: solid; */
  width: 100%;
  font-size: 20px;
  text-align: start;
  list-style: none;
}
.hairBotoxHow > li > span {
  /* border: solid; */
  color: var(--customColor);
}
.hairBotoxHow > li > a {
  text-decoration: none;
  color: var(--customColor);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hairBotoxHow > h2 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .hairBotoxHow > h3 {
    color: var(--customColor);
    width: 100%;
    text-align: start;
    font-size: 20px;
  }
}
