@import url("https://fonts.googleapis.com/css2?family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton+SC&family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&display=swap");
.offerPageContainer {
  /* border: solid red; */
  height: 100%;
}
.offerPageMainContainer {
  /* border: solid blue; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.deskImg {
  width: 100%;
  height: 100%;
}
.mobileImg {
  display: none;
  width: 100%;
  height: 100%;
}

.offerContent {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}
.offerContent > h2 {
  /* border: solid blue; */
  font-family: "Playwrite GB S", cursive;
  font-size: 40px;
  text-align: center;
  color: var(--customColor);
}
.offerContent > p {
  font-family: "Playwrite GB S", cursive;
  font-size: 20px;
  text-align: center;
  padding: 20px;
}
.offerBenefits {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.offer {
  /* border: solid green; */
  width: 30%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}
.offerImgContainer {
  border: solid red;
  width: 220px;
  height: 220px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.offerImgContent {
  /* border: solid blue; */
  width: 200px;
  height: 200px;
  border-radius: 100%;
}
.offerImgContent > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.offerHead > h3 {
  font-family: "Playwrite GB S", cursive;
  text-align: center;
}
.offerTxtContainer {
  /* border: solid green; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.offerTxtWrapper {
  /* border: solid black; */
  border-radius: 100%;
  width: 220px;
  height: 220px;
  gap: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--customColor);
  box-shadow: 5px 5px 10px black;
  position: relative;
}
.offerTxtWrapper > a {
  /* border: solid yellow; */
  border-radius: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
}
.offerTxtContent {
  /* border: solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}
#offerTxtContent1 {
  width: 40%;
}
#offerTxtContent2 {
  /* border: solid black; */
  border-radius: 100%;
  width: 100%;
  height: 100%;
  gap: 20px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#offerTxtContent1 > h3 {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-family: "Playwrite GB S", cursive;
}
#offerTxtContent2 > h3 {
  font-size: 30px;
  width: 100%;
  text-align: center;
  color: white;
  font-family: "Anton SC", sans-serif;
  letter-spacing: 2px;
}
#offerTxtContent2 > sup {
  width: 100%;
  font-size: 30px;
  color: white;
  font-family: "Anton SC", sans-serif;
  letter-spacing: 2px;
  text-align: center;
}
#offerTxtContent2 > sub {
  font-size: 20px;
  color: white;
  font-family: "Anton SC", sans-serif;
  letter-spacing: 2px;
  width: 100%;
  text-align: center;
}
#offerTxtContent3 {
  width: 80%;
}
#offerTxtContent3 > h3 {
  font-family: "Playwrite GB S", cursive;
  font-size: 30px;
  width: 100%;
  text-align: center;
}
.offerPageContactContainer {
  /* border: solid red; */
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.offerPageContactContainer > h3 {
  color: black;
  font-family: "Playwrite GB S", cursive;
}
.offerPageContactContainer > h3 > a {
  font-family: "Anton SC", sans-serif;
  font-weight: lighter;
  color: var(--customColor);
}
.offerPageContactContainer > p {
  font-family: "Playwrite GB S", cursive;
  text-align: center;
  font-size: 20px;
}
.offerPageContactContainer > p > span {
  color: var(--customColor);
}
.offerMarquee {
  /* border: solid red; */
  width: 100%;
}
.offerMarqueeHead {
  font-size: 30px;
}
@media screen and (min-width: 320px) and (max-width: 760px) {
  .offerPageMainContainer {
    /* border: solid blue; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .deskImg {
    width: 100%;
    height: 100%;
    display: none;
  }
  .mobileImg {
    display: block;
  }
  .offerBenefits {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  .offer {
    /* border: solid green; */
    min-width: 300px;
    padding: 20px;
    margin: 20px;
  }
  .offerTxtContainer {
    /* border: solid green; */
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #offerTxtContent1 > h3 {
    width: 100%;
    font-size: 20px;
  }
  #offerTxtContent1 {
    width: 100%;
  }
  #offerTxtContent3 {
    width: 100%;
  }
  #offerTxtContent3 > h3 {
    width: 100%;
    font-size: 20px;
  }
  .offerPageContactContainer > h3 {
    color: black;
    width: 80%;
    text-align: center;
  }
  .offerMarquee {
    /* border: solid red; */
    width: 100%;
  }
  .offerMarqueeHead {
    font-size: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .offerBenefits {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  .offerPageContactContainer > h3 {
    color: black;
    width: 80%;
    text-align: center;
  }
}
