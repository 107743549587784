.serviceContainer {
  /* border: solid; */
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-image: url("./../../../Images/HomeBg/serviceBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.serviceContent {
  /* border: solid; */
}
#serviceContent1 {
  width: fit-content;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#serviceContent1 > h2 {
  font-size: 30px;
  text-align: center;
  color: var(--mutedTaupe);
}
#serviceContent1 > h5 {
  font-weight: bolder;
}
#serviceContent1 > h2 > span {
  color: var(--charcoalGray);
}
#serviceContent2 {
  width: 75%;
  height: 50%;
}
#serviceContent3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}
#serviceContent3 > a,
.serviceIcon {
  color: var(--charcoalGray);
  text-decoration: underline;
}

#serviceContent3 > a > pre {
  font-size: 20px;
  color: var(--charcoalGray);
  font-weight: bold;
}
.serviceSlideContainer {
  /* border: solid; */
  height: 295px;
}
.serviceSlideContainer > h3 {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  letter-spacing: 1px;
  font-weight: lighter;
}
.serviceSlideContainer > h3 > a {
  color: var(--charcoalGray);
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}
.serviceSlideContent {
  /* border: solid; */
  height: 100%;
  padding: 10px;
}
.serviceSlideContent > img {
  width: 95%;
  height: 100%;
  margin-left: 7px;
  box-shadow: 0 0 7px var(--softGold);
}

@media only screen and (max-width: 600px) {
  .serviceContainer {
    /* border: solid; */
    height: 90vh;
    width: 100%;
  }
  #serviceContent1 > p {
    font-size: 30px;
  }
  #serviceContent1 > pre {
    font-size: 45px;
  }
  #serviceContent2 {
    /* border: solid red; */
    width: 70%;
    height: 300px;
  }

  .serviceSlideContent {
    /* border: solid; */
    height: 100%;
    width: 100%;
  }
  .serviceSlideContainer > h3 {
    /* border: solid; */

    font-size: 150%;
    letter-spacing: 1px;
  }
  .serviceSlideContent > img {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }
  #serviceContent3 {
    font-size: 130%;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .serviceContainer {
    /* border: solid; */
    height: 90vh;
    width: 100%;
  }
  #serviceContent1 > p {
    font-size: 30px;
  }
  #serviceContent1 > pre {
    font-size: 45px;
  }
  #serviceContent2 {
    /* border: solid red; */
    width: 70%;
    height: 300px;
    margin: 10px;
  }
  .serviceSlideContent {
    /* border: solid; */
    height: 100%;
    width: 90%;
    margin-left: 15px;
  }
  .serviceSlideContainer > h3 {
    /* border: solid; */

    font-size: 100%;
    letter-spacing: 1px;
  }
  .serviceSlideContent > img {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }
  #serviceContent3 {
    font-size: 130%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 990px) {
  .serviceContainer {
    /* border: solid; */
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .serviceContent {
    /* border: solid; */
  }
  #serviceContent1 {
    width: fit-content;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #serviceContent1 > p {
    font-size: 30px;
    font-weight: lighter;
  }
  #serviceContent1 > pre {
    font-size: 50px;
    font-weight: bolder;
  }
  #serviceContent2 {
    width: 90%;
    height: 50%;
  }
  #serviceContent3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
  }

  .serviceSlideContainer {
    /* border: solid; */
    height: 295px;
  }
  .serviceSlideContainer > h3 {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    letter-spacing: 1px;
    font-weight: lighter;
  }
  .serviceSlideContent {
    /* border: solid; */
    height: 100%;
  }
  .serviceSlideContent > img {
    width: 95%;
    height: 100%;
    margin-left: 7px;
    filter: grayscale(1);
  }
  a {
    color: black;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .serviceContainer {
    /* border: solid; */
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .serviceContent {
    /* border: solid; */
  }
  #serviceContent1 {
    width: fit-content;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #serviceContent1 > p {
    font-size: 30px;
    font-weight: lighter;
  }
  #serviceContent1 > pre {
    font-size: 50px;
    font-weight: bolder;
  }
  #serviceContent2 {
    width: 75%;
    height: 50%;
  }
  #serviceContent3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
  }

  .serviceSlideContainer {
    /* border: solid; */
    height: 295px;
  }
  .serviceSlideContainer > h3 {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    letter-spacing: 1px;
    font-weight: lighter;
  }
  .serviceSlideContent {
    /* border: solid; */
    height: 100%;
  }
  .serviceSlideContent > img {
    width: 95%;
    height: 100%;
    margin-left: 7px;
    object-fit: cover;
    filter: grayscale(1);
  }
  a {
    color: black;
  }
}
