.vidContainer {
  /* border: solid red; */
  height: 90vh;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: black;
}
.videoContent {
  /* border: solid red; */
  height: 80%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  bottom: 100px;
  margin-top: 0px;
}
.vidTxt {
  /* border: solid; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  color: white;
  position: absolute;
  bottom: 50px;
  font-size: 20px;
  text-shadow: 0px 0px 5px black;
  cursor: pointer;
}
.vidTxt > a > pre:hover {
  color: #cc2228;
  text-shadow: none;
}
.vidTxt > a {
  text-decoration: none;
  color: white;
}
.vidTxt > a > pre {
  text-decoration: none;
  color: white;
}
.vidIcon {
  font-size: 20px;
  margin-top: 10px;
}

.vidUnderline {
  border: solid #cc2228 2px;
  width: 90px;
  position: absolute;
  bottom: 30px;
}
.vidContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .vidContainer {
    /* border: solid red; */
    width: 100%;
    height: 400px;
  }
  .vidContainer > video {
    width: 100%;
    height: 100%;
  }
  .videoContent {
    /* border: solid red; */
    height: 100%;
    width: 100%;
  }
  .vidTxt {
    bottom: 50px;
    font-size: 100%;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .homeFormWrapper {
    /* border: solid; */
    height: 80vh;
    width: 90%;
  }
  .homeFormExit {
    /* border: solid; */
    width: 100%;
    height: 10%;
  }
  .vidContainer {
    /* border: solid red; */
    width: 100%;
  }
  .videoContent {
    /* border: solid red; */
    height: 90%;
    width: 100%;
  }
  .vidTxt {
    bottom: 60px;
    font-size: 120%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 990px) {
  .homeFormWrapper {
    /* border: solid; */
    height: 80vh;
    width: 60%;
  }
  .homeFormExit {
    /* border: solid; */
    width: 100%;
    height: 10%;
  }
}
