.hairTreatmentLandingMove {
  /* border: solid red; */
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.hairTreatmentLandingMove > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  top: 30px;
  z-index: -1;
}
.hairTreatmentMoveContent {
  /* border: solid green; */
  width: 400px;
  height: 400px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hairTreatmentMoveInnerContent {
  border: dotted black;
  width: 95%;
  height: 95%;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.hairTreatmentMoveInnerContent > h2 {
  font-size: 40px;
  color: var(--customColor);
}
.hairTreatmentMoveInnerContent > p {
  font-size: 15px;
  width: 80%;
  text-align: center;
}
.hairTreatmentMoveInnerContent > a > button {
  width: 100px;
  height: 40px;
  background-color: var(--customColor);
  font-size: 15px;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.hairTreatmentMoveInnerContent > a > button:hover {
  border: solid var(--customColor);
  color: var(--customColor);
  background-color: white;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hairTreatmentMoveContent {
    /* border: solid green; */
    width: 300px;
    height: 300px;
    border-radius: 100%;
  }
  .hairTreatmentMoveInnerContent > h2 {
    font-size: 25px;
    color: var(--customColor);
    padding: 10px;
  }
  .hairTreatmentLandingMove > img {
    width: 100%;
    height: 100%;
object-position: -400px;
  }
}
