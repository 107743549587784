.upperNavContainer {
  /* border: solid red; */
  /* position: fixed; */
  top: 0;
  width: 100%;
  z-index: 6;
  height: 40px;
  background-color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.upperNavContainer > h3 {
  text-align: center;
  padding: 10px;
  font-size: 15px;
  color: white;
}
.upperNavContainer > h3 > span {
  /* border: solid white; */
  padding: 3px;
  border-radius: 5px;
  margin-left: 10px;
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #00ff00,
    #007bff,
    #8a00d4
  );
  background-size: 400% 400%;
  animation: rainbow-bg 5s linear infinite;
}

@keyframes rainbow-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.upperNavContainer > h3 > span > a {
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  text-decoration: none;
}
.upperNavInsta {
  position: absolute;
  right: 20px;
}
.upperNavIcon {
  color: white;
  font-size: 30px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .upperNavContainer {
    /* border: solid red; */
    /* position: fixed; */
    height: 50px;
  }
  .upperNavContainer > h3 {
    text-align: center;
    padding: 10px;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
  .upperNavInsta {
    position: static;
    margin-right: 20px;
  }
  .upperNavIcon {
    color: white;
    font-size: 30px;
  }

}