.homeBlog {
    /* border: solid red; */
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .homeBlogHead {
    width: 100%;
    /* border: solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeBlogHead > h2 {
    text-align: center;
    font-size: 30px;
  }
  .homeBlogHead > h2 > span {
    text-align: center;
    font-size: 30px;
    color: var(--customColor);
  }
  .homeBlogMain {
    width: 100%;
    padding: 10px;
    /* border: solid green; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .homeBlogMainContainer {
    width: 100%;
    height: 100%;
    padding: 10px;
    /* border: solid green; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
  .blogCard {
    /* border: solid blue; */
    width: 400px;
    height: 450px;
    margin: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 0 5px grey;
  }
  
  .blogImg {
    /* border: solid brown; */
    width: 100%;
    height: 200px;
    border-radius: 20px;
    overflow: hidden;
  }
  .blogImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 500ms ease-in-out;
  }
  .blogCard:hover .blogImg > img {
    transform: scale(1.1);
  }
  .blogCard > h4 {
    /* border: solid green; */
    width: 100%;
    text-align: start;
    padding: 10px;
    color: var(--customColor);
    transition: 500ms ease-in-out;
    font-size: 20px;
  }
  .blogCard:hover .blogCardH4 {
    color: var(--customColor);
  }
  .blogCard > p {
    font-size: 18px;
    padding: 10px;
    color: grey;
    text-align: justify;
  }
  .blogBtn {
    width: 100%;
    padding: 10px;
  }
  .blogBtn > a {
    text-decoration: none;
  }
  .blogBtn > a > button {
    border: none;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--customColor);
    color: white;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    font-weight: bold;
  }
  .homeBlogBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeBlogBtn > a > button {
    border: 2px solid white;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--customColor);
    color: white;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      border: 2px solid var(--customColor);
      color: var(--customColor);
      background-color: white;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .blogCard {
      /* border: solid blue; */
      width: 400px;
      height: 450px;
      margin: 10px;
      margin-top: 30px;
    }
  }
  