.nestedNav {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 20px;
  box-shadow: 0 0 10px grey;
}

.nestedNav > li {
  margin: 10px;
  list-style: none;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.nestedNav > li > a {
  color: var(--customColor);
  text-decoration: none;
}
.nestedNav1 > .active {
  text-decoration: underline;
}
.nestedNav2 > .active {
  text-decoration: underline;
}
.nestedNav3 > .active {
  text-decoration: underline;
}
.nestedNav4 > .active {
  text-decoration: underline;
}
.nestedNav5 > .active {
  text-decoration: underline;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .nestedNav {
    /* border: solid red; */
    height: 10vh;
    padding: 10px;
    overflow-y: hidden;
  }
  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(58, 58, 58);
  }
  ::-webkit-scrollbar-track {
    width: 10px;
    background-color: gray;
  }
}
