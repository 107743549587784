.workContainer {
  /* border: solid; */
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.workContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.innerWorkContent {
  /* border: solid red; */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  /* background-color: var(--customColorTrans); */
  background-color:#6a7ba297;
  color: white;
  position: absolute;
  top: 0;
}
.workContentTxt1 > h2 {
  font-size: 30px;
}
.workContentTxt2 > p {
  font-size: 20px;
  text-align: justify;
}
.workContentTxt3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.workContentTxt3 > a > p {
  font-size: 20px;
}
.workContentTxt3 > a {
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.workIcon {
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .innerWorkContent {
    /* border: solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    color: white;
    position: static;
    top: 0;
  }
}
