.hairColorStreakLandingCollection {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    background-color: white;
  }
  .hairColorStreakCollectionWrapper {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
  }
  .hairColorStreakCollectionHead {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .hairColorStreakCollectionHead > h2 {
    width: 100%;
    text-align: center;
    font-size: 50px;
    color: var(--customColor);
    font-weight: 400;
  }
  .hairColorStreakCollectionHead > p {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }
  .hairColorStreakCollectionContent {
    /* border: solid blue; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px;
  }
  .hairColorStreakCollectionCard {
    /* border: solid green; */
    width: 25%;
    height: 500px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 5px rgb(175, 175, 175);
  }
  .hairColorStreakCollectionCardContent {
    /* border: solid brown; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    background-color: white;
  }
  #hairColorStreakCollectionCardContent1 {
    height: 40%;
  }
  #hairColorStreakCollectionCardContent2 {
    height: 40%;
    padding: 10px;
    /* background-color: var(--customColor); */
  }
  #hairColorStreakCollectionCardContent3 {
    height: 20%;
    /* background-color: black; */
  }
  .hairColorStreakCollectionCardContent > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hairColorStreakCollectionCardItem {
    /* border: solid white; */
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
  }
  .hairColorStreakCollectionCardContent > p {
    width: 90%;
    text-align: center;
    color: black;
  }
  .hairColorStreakCollectionCardItem > h3 {
    color: white;
    font-weight: 400;
  }
  .hairColorStreakCollectionCardItem:hover {
    background-color: var(--customColor);
  }
  .hairColorStreakCollectionCardContent > h2 {
    color: black;
    font-weight: 400;
  }
  .hairColorStreakCollectionCardContent > button {
    width: 35%;
    height: 40%;
    font-size: 15px;
    background-color: white;
    border: none;
    transition: 300ms ease-in-out;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 400;
    background-color: var(--customColor);
    color: white;
  }
  .hairColorStreakCollectionCardContent > button > a {
    width: 35%;
    height: 40%;
    font-size: 15px;
    border: none;
    transition: 300ms ease-in-out;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 400;
    text-decoration: none;
    color: white;
  }
  .hairColorStreakCollectionCardContent > button:hover {
    border: 2px solid var(--customColor);
    background-color: white;
  }
  .hairColorStreakCollectionCardContent
    > button:hover
    .hairTreatmentCollectionLink {
    color: var(--customColor);
  }
  .hairColorStreakCollectionImg {
    border: solid;
    width: 100%;
    height: 100%;
  }
  .hairColorStreakCollectionImg > img {
    width: 100%;
    height: 100%;
    object-position: 0 10px;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .hairColorStreakCollectionWrapper {
      /* border: solid red; */
      padding: 10px;
    }
    .hairColorStreakCollectionContent {
      /* border: solid blue; */
      flex-direction: column;
    }
    .hairColorStreakCollectionCard {
      /* border: solid green; */
      min-width: 300px;
      height: 500px;
    }
  }
  