.meadowsLocAboutContainer {
    /* border: solid; */
    margin-top: 10%;
  
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .meadowsLocAboutContainer > p {
    /* border: solid red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .meadowsLocAboutContainer>h1{
  text-align: center;
  font-size: 40px;
  font-weight: lighter;
  color: var(--customColor);
  }
  .meadowsLocAboutContainer > p > ul > li {
    list-style: none;
    text-align: center;
    font-size: 120%;
    margin: 20px; 
  }
  