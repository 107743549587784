.locationImgContainer {
    /* border: solid red; */
    height: 75vh;
    width: 100%;
  }
  .locationImgContainer > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width:600px) {
    .locationImgContainer {
      /* border: solid red; */
      height: 30vh;
      width: 100%;
    }
  }
  @media screen and (max-width:990px) and (min-width:600px) {
    .locationImgContainer {
      /* border: solid red; */
      height: 50vh;
      width: 100%;
    }
  }