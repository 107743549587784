

.facialCardContainer {
  /* border: solid blue; */
  width: 100%;
  height: 300vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.facialCardMain {
  /* border: solid red; */
  position: relative;
  width: 25%;
  margin: 30px;
}
.facialCardWrapper {
  /* border: solid; */
  height: 320px;
  width: 90;
  margin: 15px;
  position: relative;
  overflow: hidden;
}
.facialCardWrapper > img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 600ms ease-in-out;
}
.facialCardWrapper:hover .facialCardImg {
  transform: scale(1.2);
}

.facialCardCover {
  /* border: solid green; */
  height: 100%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  display: none;
}
.facialCardWrapper:hover .facialCardCover {
  display: flex;
}
.facialCardTxtContainer {
  /* border: solid; */
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}
.facialCardHead > h3 {
  font-size: 150%;
  color: white;
}

.facialCardPara {
  font-weight: bold;
  color: white;
  text-align: justify;
}
.facialCardPara > p {
  font-size: 90%;
}
.facialCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}
.facialGoto {
  border: 2px whitesmoke solid;
  display: flex;
  width: 280px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 3;
  bottom: -5px;
  left: 45px;
  background-color: white;
}
.goTo1 {
  margin-left: 20px;
}
.goTo1 > h3 {
  color: var(--customColor);
  font-size: 120%;
  text-align: center;
}
.goTo2 {
  margin-right: 20px;
}
.respFacialServiceContainer {
  /* border: solid green; */
  height: 250px;
  width: 100%;
  margin: 0px;
  position: relative;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.respFacialServiceCardCover {
  /* border: solid blue; */
  height: 100%;
  width: 90%;
  background-color: var(--customRed);
  display: flex;
  justify-content: center;
  align-items: center;
}

.respFacialServiceCardTxtContainer {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 0;
}
.respFacialServiceCardHead > h3 {
  font-size: 120%;
  color: white;
}

.respFacialServiceCardPara {
  font-weight: bold;
  color: white;
  text-align: justify;
  width: 90%;
}
.respFacialServiceCardPara > p {
  font-size: 100%;
  text-align: center;
}
.respFacialServiceCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 30px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}

@media only screen and (min-width: 320px) {
  .facialCardContainer {
    /* border: solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
    margin: 0;
  }
  .facialCardMain {
    border: solid yellow;
    position: relative;
    height: 480px;
    max-width: 500px;
    margin: 20px;
  }
  .facialCardWrapper > img {
    position: static;
    width: 100%;
    height: 100%;
  }
  .facialCardWrapper {
    /* border: solid green; */
    height: 200px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .facialGoto {
    border: 2px whitesmoke solid;
    width: 90%;
    height: 35px;
    bottom: -210px;
    left: 5%;
    background-color: white;
    text-align: center;
  }
  .facialGoto > .goTo1 > h3 {
    font-size: 100%;
  }

  .respFacialServiceCardCover {
    /* border: solid blue; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .respFacialServiceContainer {
    display: flex;
    /* border: solid; */
  }
}
@media only screen and (min-width: 768px) {
  .facialCardContainer {
    /* border: solid red; */
    height: 600vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
    margin-top: 0;
  }
  .facialCardMain {
    /* border: solid yellow; */
    position: relative;
    max-width: 300px;
    height: 460px;
    margin: 30px;
  }
  .facialCardWrapper > img {
    position: static;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .facialCardWrapper {
    /* border: solid green; */
    height: 200px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .facialGoto {
    border: 2px whitesmoke solid;
    width: 90%;
    height: 40px;
    bottom: -155px;
    text-align: center;
    left: 15px;
    background-color: white;
  }
  .respFacialServiceContainer {
    display: flex;
    /* border: solid; */
    margin-top: 0px;
  }
  .respFacialServiceCardCover {
    /* border: solid blue; */
    height: 150%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .respFacialServiceCardTxtContainer {
    gap: 30px;
  }
  .respFacialServiceCardHead > h3 {
    font-size: 150%;
    color: white;
  }
}
@media only screen and (min-width: 990px) {
  .respFacialServiceContainer {
    display: none;
  }
  .facialCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 450vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .facialCardMain {
    /* border: solid red; */
    position: relative;
    margin: 30px;
    min-width: 400px;
    height: 400px;
  }
  .facialCardWrapper {
    /* border: solid; */
    height: 320px;
    width: 90;
    margin: 15px;
    position: relative;
    overflow: hidden;
  }
  .facialCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .facialCardWrapper:hover .facialCardImg {
    transform: scale(1.2);
  }

  .facialCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .facialCardWrapper:hover .facialCardCover {
    display: flex;
  }
  .facialCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .facialCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .facialCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .facialCardPara > p {
    font-size: 90%;
  }
  .facialCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .facialGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    text-align: center;
    z-index: 3;
    bottom: -5px;
    left: 45px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 120%;
  }
  .goTo2 {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .respFacialServiceContainer {
    display: none;
  }
  .facialCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 400vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .facialCardMain {
    /* border: solid red; */
    position: relative;
    margin: 30px;
    min-width: 400px;
    height: 400px;
  }
  .facialCardWrapper {
    /* border: solid; */
    height: 320px;
    width: 90;
    margin: 15px;
    position: relative;
    overflow: hidden;
  }
  .facialCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: 600ms ease-in-out;
  }

  .facialCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .facialCardWrapper:hover .facialCardCover {
    display: flex;
  }
  .facialCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .facialCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .facialCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .facialCardPara > p {
    font-size: 90%;
  }
  .facialCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .facialGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    text-align: center;
    z-index: 3;
    bottom: -5px;
    left: 45px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 120%;
  }
  .goTo2 {
    margin-right: 20px;
  }
}
