.academyModel-container {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.academyModelHead-container {
  /* border: solid blue; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.academyModelHead-container > h2 {
  color: var(--charcoalGray);
  text-align: center;
  font-size: 40px;
}
.academyModelMain-container {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.academyModelMain-content {
  /* border: solid brown; */
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.academyModelMain-content > a > button {
  border: none;
  width: 150px;
  height: 40px;
  color: white;
  background-color: var(--customColor);
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.academyModelMain-content > p {
  font-size: 18px;
  text-align: justify;
}
.academyModelMain-content > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .academyModelMain-container {
    /* border: solid green; */
    flex-direction: column-reverse;
  }
  .academyModelMain-content {
    /* border: solid brown; */
    width: 100%;

    align-items: center;
  }
}
