.headSpaHeadContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    padding: 30px;
  }
  .headSpaHeadContainer>h1{
      color: var(--customColor);
      width: 100%;
      text-align: center;
  }
  .headSpaHeadContainer > h2 {
      color: var(--customColor);
      text-align: center;
      width: 100%;
      font-size: 25px;
    }
    .headSpaHeadContainer > h3 {
      color: var(--customColor);
      text-align: start;
      width: 100%;
      font-size: 25px;
    }
    .headSpaHeadContainer > h4 {
      color: var(--customColor);
      text-align: start;
      width: 100%;
      font-size: 25px;
    }
    .headSpaHeadContainer > h5 {
      color: var(--customColor);
      text-align: start;
      width: 100%;
      font-size: 25px;
    }
    
    .headSpaHeadContainer > ul > li {
      list-style: none;
      margin: 10px;
      font-size: 20px;
      text-align: justify;
    }
    .headSpaHeadContainer > ul > li > h6 {
      font-size: 20px;
      text-align: justify;
      color: var(--customColor);
    }
    .headSpaHeadContainer > ul > li > a {
      text-decoration: none;
      color: var(--customColor);
    }
    
    .headSpaHeadContainer > ul > li > span {
      color: var(--customColor);
      font-weight: bold;
    }
    .headSpaHeadContainer > p {
      text-align: justify;
      font-size: 20px;
    }
    .headSpaHeadContainer > p > a {
      color: var(--customColor);
      text-decoration: none;
    }
    @media screen and (min-width: 320px) and (max-width: 768px) {
      .headSpaHeadContainer > h2 {
        text-align: center;font-size: 20px;
      }
      .headSpaHeadContainer > h3 {
        text-align: center;font-size: 20px;
      }
      .headSpaHeadContainer > h4 {
        text-align: center;font-size: 20px;
      }
      .headSpaHeadContainer > h5 {
        text-align: center;font-size: 20px;
      }
    }