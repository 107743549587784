
.unisexSalonContainer {
    /* border: solid red; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  gap: 20px;
  padding: 20px;
}
.unisexHeadContainer {
  /* border: solid; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--customColor);
}
.unisexHeadContainer > h2 {
  font-size: 30px;
  color: var(--customColor);
}
.unisexUnderline {
  border: 2px solid;
  width: 100px;
  color: var(--customColor);
}
.unisexParaContainer {
  /* border: solid; */
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.unisexParaContainer > p {
  /* border: solid blue; */
  text-align: justify;
  width: 90%;
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .unisexSalonContainer {
    /* border: solid red; */
    height: 100%;
    width: 100%;
    margin-top: 40px;
    gap: 10px;
  }
  .unisexHeadContainer {
    /* border: solid; */
    height: 70px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--customColor);
  }
  .unisexHeadContainer > h2 {
    font-size: 150%;
    color: var(--customColor);
    text-align: center;
  }
  .unisexParaContainer > p {
    /* border: solid blue; */
    text-align: center;
    width: 90%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* @media screen and (min-width: 768px) {
  .unisexSalonContainer {
    border: solid red;
    height: 100%;
    width: 100%;
    margin-top: 50px;
  }
  .unisexHeadContainer {
    border: solid;
    height: 70px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--customColor);
  }
  .unisexHeadContainer > h2 {
    font-size: 150%;
    color: var(--customColor);
    text-align: center;
  }
  .unisexParaContainer > p {
    border: solid blue;
    text-align: center;
    width: 90%;
    font-size: 100%;
  }
} */
/* @media screen and (min-width: 1200px) and (max-width:1400px){
  .unisexSalonContainer {
    border: solid red;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }
  .unisexHeadContainer {
    border: solid;
    height: 100%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--customColor);
  }
  .unisexHeadContainer > h2 {
    font-size: 40px;
    color: var(--customColor);
  }
  .unisexUnderline {
    border: 2px solid;
    width: 100px;
    color: var(--customColor);
  }
  .unisexParaContainer {
    border: solid;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
  }
  .unisexParaContainer > p {
    border: solid blue;
    text-align: start;
    width: 90%;
    font-size: 20px;
  }
} */
