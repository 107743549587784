
.hairFormWrapper {
  /* border: solid; */
  height: 80vh;
  width: 100%;
  top: 14vh;
  position: fixed;
  z-index: 4;
  left: 0%;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hairFormExit {
  /* border: solid; */
  width: 40%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.645);
}
.formIcon {
  font-size: 150%;
  color: white;
  margin-right: 10px;
}
.hairCardContainer {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 230vh;
}
.hairCardMain {
  /* border: solid red; */
  position: relative;
  margin: 30px;
}
.hairCardWrapper {
  /* border: solid; */
  height: 450px;
  width: 600px;
  margin: 15px;
  position: relative;
  overflow: hidden;
}
.hairCardWrapper > img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 600ms ease-in-out;
}
.hairCardWrapper:hover .hairCardImg {
  transform: scale(1.2);
}

.hairCardCover {
  /* border: solid; */
  height: 100%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  display: none;
}
.hairCardWrapper:hover .hairCardCover {
  display: flex;
}
.hairCardTxtContainer {
  /* border: solid; */
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
}
.hairCardHead > h3 {
  font-size: 30px;
  color: white;
}

.hairCardPara {
  font-weight: bold;
  color: white;
  text-align: justify;
  word-spacing: 2px;
}
.hairCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}
.hairGoto {
  border: 2px whitesmoke solid;
  display: flex;
  width: 80%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 3;
  bottom: -6px;
  left: 45px;
  background-color: white;
}
.goTo1 {
  margin-left: 20px;
}
.goTo1 > h3 {
  color: var(--customColor);
  font-size: 20px;
}
.goTo2 {
  margin-right: 20px;
}
.respHairServiceContainer {
  /* border: solid green; */
  height: 300px;
  width: 100%;
  margin: 0px;
  position: relative;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
}
.respHairServiceCardCover {
  /* border: solid blue; */
  height: 100%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.respHairServiceCardTxtContainer {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 0px;
  margin-top: 0;
}
.respHairServiceCardHead > h3 {
  font-size: 160%;
  color: white;
}

.respHairServiceCardPara {
  font-weight: bold;
  color: white;
  text-align: justify;
  width: 90%;
}
.respHairServiceCardPara > p {
  font-size: 80%;
}
.respHairServiceCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 30px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}
@media screen and (min-width: 320px) {
  .hairCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .hairCardMain {
    /* border: solid red; */
    position: relative;
    height: 100%;
    max-width: 300px;
    margin: 30px;
  }
  .respHairServiceContainer {
    display: flex;
  }
  .hairCardWrapper {
    /* border: solid; */
    height: 200px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .hairCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .hairCardWrapper:hover .hairCardImg {
    transform: scale(1.2);
  }

  .hairCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .hairCardWrapper:hover .hairCardCover {
    display: none;
  }
  .hairCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .hairCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .hairCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .respHairServiceCardPara > p {
    font-size: 90%;
  }
  .hairCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .hairGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 90%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -4%;
    left: 15px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 20px;
  }
  .goTo2 {
    margin-right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .hairCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
    margin-top: 0%;
  }
  .hairCardMain {
    /* border: solid red; */
    position: relative;
    max-width: 300px;
    margin: 30px;
  }
  .respHairServiceContainer {
    display: flex;
  }
  .hairCardWrapper {
    /* border: solid; */
    height: 250px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .hairCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .hairCardWrapper:hover .hairCardImg {
    transform: scale(1.2);
  }

  .hairCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .hairCardWrapper:hover .hairCardCover {
    display: none;
  }
  .hairCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .hairCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .hairCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .respHairServiceCardPara > p {
    font-size: 90%;
  }
  .hairCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .hairGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -4%;
    left: 35px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 20px;
  }
  .goTo2 {
    margin-right: 20px;
  }
}
@media screen and (min-width: 990px) {
  .hairCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .hairCardMain {
    /* border: solid red; */
    position: relative;
    min-width: 35%;
    margin: 5%;
  }
  .hairCardWrapper {
    /* border: solid; */
    height: 320px;
    width: 90%;
    margin: 15px;
    position: relative;
    overflow: hidden;
  }
  .hairCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .hairCardWrapper:hover .hairCardImg {
    transform: scale(1.2);
  }

  .hairCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .hairCardWrapper:hover .hairCardCover {
    display: flex;
  }
  .hairCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .hairCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .hairCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .hairCardPara > p {
    font-size: 90%;
  }
  .hairCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .hairGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -5px;
    left: 35px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 20px;
  }
  .goTo2 {
    margin-right: 20px;
  }
  .respHairServiceContainer {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .hairCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .hairCardMain {
    /* border: solid red; */
    position: relative;
    width: 30%;
    margin: 5%;
  }
  .hairCardWrapper {
    /* border: solid; */
    height: 320px;
    width: 90%;
    margin: 15px;
    position: relative;
    overflow: hidden;
  }
  .hairCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .hairCardWrapper:hover .hairCardImg {
    transform: scale(1.2);
  }

  .hairCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .hairCardWrapper:hover .hairCardCover {
    display: flex;
  }
  .hairCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .hairCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .hairCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .hairCardPara > p {
    font-size: 90%;
  }
  .hairCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .hairGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -5px;
    left: 40px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 20px;
  }
  .goTo2 {
    margin-right: 20px;
  }
}
