.OrganicFacialBook {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
}
.OrganicFacialBook > h5 {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.OrganicFacialBook > p {
  font-size: 20px;
  text-align: justify;
}
.OrganicFacialBook > p > a {
  color: var(--customColor);
  text-decoration: none;
}
