.hairColorStreakLandingProgram {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background-color: white;
  }
  .hairColorStreakHeadProgram {
    /* border: solid green; */
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .hairColorStreakHeadProgram > h2 {
    font-size: 50px;
    font-weight: 400;
    color: var(--customColor);
  }
  .hairColorStreakHeadProgram > p {
    font-size: 20px;
  }
  .hairColorStreakMainProgram {
    /* border: solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    padding: 20px;
  }
  .hairColorStreakProgramCard {
    /* border: solid brown; */
    width: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }
  .hairColorStreakProgramCardContent1 {
    border: 5px solid black;
    width: 300px;
    height: 300px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    transition: 300ms ease-in-out;
  }
  .hairColorStreakProgramCardContent1 > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    position: relative;
    background-color: black;
  }
  .hairColorStreakProgramCardCover {
    /* border: solid red; */
    background-color: rgba(0, 0, 0, 0.619);
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    transform: translateY(100%);
    transition: 300ms ease-in-out;
  }
  .hairColorStreakProgramCardCover > h4 {
    font-size: 30px;
    color: var(--customColor);
  }
  .hairColorStreakProgramCardCover > p {
    font-size: 20px;
    color: white;
  }
  .hairColorStreakProgramCardContent1:hover {
    border: 5px solid #2e2e30;
  }
  .hairColorStreakProgramCardContent1:hover .hairColorStreakProgramCardCover {
    transform: translateX(0);
  }
  
  .hairColorStreakProgramCardCover:hover .hairColorStreakProgramCardCover > h4 {
    transform: scale(1.2);
  }
  .hairColorStreakProgramCardContent2 > h3 {
    font-size: 30px;
    color: var(--customColor);
    font-weight: 400;
  }
  .hairColorStreakProgramCardContent3 {
    /* border: solid blue; */
    width: 60%;
  }
  .hairColorStreakProgramCardContent3 > p {
    width: 100%;
    text-align: center;
    font-size: 15px;
  }
  .hairColorStreakMainProgram > a {
    text-decoration: none;
  }
  .hairColorStreakMainProgram > a > button {
    height: 50px;
    padding: 20px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: none;
    background-color: var(--customColor);
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  .hairColorStreakMainProgram > a > button:hover {
    border: solid var(--customColor);
    color: var(--customColor);
    background-color: white;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .hairColorStreakLandingProgram {
      /* border: solid red; */
      padding: 10px;
    }
    .hairColorStreakMainProgram {
      /* border: solid blue; */
      flex-direction: column;
      padding: 0px;
    }
    .hairColorStreakProgramCard {
      /* border: solid brown; */
      width: 100%;
      min-width: 300px;
      padding: 20px;
    }
    .hairColorStreakProgramCardContent1 {
      width: 200px;
      height: 200px;
    }
  }
  