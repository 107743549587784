.pediManicureHead {
  /* border: solid blue; */
  width: 90%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.pediManicureHead > h1 {
  font-size: 30px;
  color: var(--customColor);
  text-align: center;
}
.pediManicureHead > h2 {
  font-size: 30px;
  color: var(--customColor);
  text-align: center;
}
.pediManicureHead > h3 {
  font-size: 25px;
  color: var(--customColor);
  text-align: start;
  width: 100%;
}
.pediManicureHead > h5 {
  font-size: 25px;
  color: var(--customColor);
  text-align: start;
  width: 100%;
}
.pediManicureHead > p {
  width: 100%;
  font-size: 20px;
  text-align: justify;
}
.pediManicureHead > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.pediManicureHead {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.pediManicureHead > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.pediManicureHead > p > span {
  color: var(--customColor);
  font-size: 20px;
  font-weight: bold;
}

.pediManicureHead > ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.pediManicureHead > ul > h4 {
  font-size: 22px;
  color: var(--customColor);
  text-align: start;
  width: 100%;
}
.pediManicureHead > ul > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.pediManicureHead > ul > li {
  font-size: 20px;
  margin: 10px;
  list-style: none;
  
}
.pediManicureHead > ul > li > p {
  text-align: justify;
}
.pediManicureHead > ul > li > span {
  color: var(--customColor);
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.pediManicureHead > ul > li > span > img {
  width: 20px;
  height: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .pediManicureHead > h1 {
    font-size: 25px;
    color: var(--customColor);
    text-align: center;
  }
  .pediManicureHead > h3 {
    text-align: center;
  }
  .pediManicureHead > h5 {
    text-align: center;
  }
}
