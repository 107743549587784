.abtnavbg {
  background-color: black;
  height: 100px;
  width: 100%;
}

.aboutMyselfPageContainer {
  /* border: solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.aboutMyselfTxtContainer {
  /* border: solid; */
  width: 90%;
  margin-top: 10%;
}
.aboutMyselfTxtContainer > h2 {
  color: var(--customColor);
}
.aboutMyselfTxtContainer > li {
  list-style: none;
  margin: 30px;
  font-size: 20px;

}
.aboutMyselfContactContainer {
  border: solid;
  width: 30%;
  border-radius: 10px;
  height: 70px;
  display: flex;
  margin-top: 6%;
  justify-content: center;
  align-items: center;
  color: var(--customColor);
}
.aboutMyselfContactContainer > h2 > a {
  text-decoration: none;
  color: var(--customColor);
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .abtnavbg {
    background-color: black;
    height: 100px;
    width: 100%;
  }

  .aboutMyselfPageContainer {
    /* border: solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .aboutMyselfImgContainer {
    /* border: solid green; */
    width: 100%;
  }
  .aboutMyselfImgContainer > img {
    width: 100%;
    height: 100%;
  }
  .aboutMyselfTxtContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .aboutMyselfTxtContainer > h2 {
    /* border: solid; */
    text-align: center;
    width: 90%;
  }
  .aboutMyselfTxtContainer > li {
    /* border: solid; */
    list-style: none;
    margin: 10px;
    font-size: 100%;
  }
  .aboutMyselfContactContainer {
    border: solid red;
    width: 90%;
    border-radius: 10px;
    height: 70px;
    display: flex;
    margin-top: 6%;
    justify-content: center;
    align-items: center;
  }
  .aboutMyselfContactContainer > h2 {
    /* border: solid; */
    text-decoration: none;
    color: var(--customColor);
    text-align: center;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .abtnavbg {
    background-color: black;
    height: 100px;
    width: 100%;
  }

  .aboutMyselfPageContainer {
    /* border: solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .aboutMyselfImgContainer {
    border: solid green;
  }
  .aboutMyselfTxtContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .aboutMyselfTxtContainer > h2 {
    /* border: solid; */
    text-align: center;
    width: 90%;
  }
  .aboutMyselfTxtContainer > li {
    /* border: solid; */
    list-style: none;
    margin: 10px;
    font-size: 100%;
  }
  .aboutMyselfContactContainer {
    /* border: solid; */
    width: 90%;
    border-radius: 10px;
    height: 70px;
    display: flex;
    margin-top: 6%;
    justify-content: center;
    align-items: center;
  }
  .aboutMyselfContactContainer > h2 > a {
    text-decoration: none;
    color: var(--customColor);
    text-align: center;
  }
}
@media screen and (max-width: 1200px) and (min-width: 990px) {
  .abtnavbg {
    background-color: black;
    height: 100px;
    width: 100%;
  }

  .aboutMyselfPageContainer {
    /* border: solid red; */
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
  .aboutMyselfImgContainer {
    /* border: solid green; */
  }
  .aboutMyselfTxtContainer {
    /* border: solid; */
    width: 90%;
    margin-top: 10%;
  }
  .aboutMyselfTxtContainer > li {
    list-style: none;
    margin: 30px;
    font-size: 130%;
  }
  .aboutMyselfContactContainer {
    border: solid;
    width: 40%;
    border-radius: 10px;
    height: 70px;
    display: flex;
    margin-top: 6%;
    justify-content: center;
    align-items: center;
    color: var(--customColor);
  }
  .aboutMyselfContactContainer > h2 > a {
    text-decoration: none;
    color: var(--customColor);
  }
}
@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .abtnavbg {
    background-color: black;
    height: 120px;
    width: 100%;
  }
}
