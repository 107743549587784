.haircutImg {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.haircutImg > img {
  width: 100%;
  height: 100%;
}
@media screen and (min-width:320px) and (max-width:768px) {
  .haircutImg {
    /* border: solid red; */
    width: 100%;
  }
}