.questionAnswerCard-container {
  /* border: solid brown; */
  width: 900px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 5px black;
  border-radius: 5px;
  padding: 25px;
  position: relative;
}

.questionAnswerCard-cover {
  /* border: solid yellow; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.questionAnswerCard-coverContent1 {
  height: 35%;
  width: 100%;
  /* border: solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.questionAnswerCard-coverItem1 {
  border: 2px solid var(--customColor);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.questionAnswerCard-coverItem1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.questionAnswerCard-coverItem2 {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.questionAnswerCard-coverInnerItem1 {
  height: 30%;
  width: 100%;
  /* border: solid pink; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  padding-left: 10px;
}
.QAPageCardCover-innerItem2 {
  height: 70%;
  width: 100%;
  /* border: solid blue; */
}
.questionAnswerCard-coverBlock1 > h3 {
  color: var(--customColor);
}
.questionAnswerCard-coverBlock2 {
  padding: 2px;
  border-radius: 5px;
  color: white;
  background-color: var(--customColor);
}
.questionAnswerCard-coverBlock3 {
  font-weight: 500;
}
.questionAnswerCard-coverBlock3 > span {
  color: var(--customColor);
}
.questionAnswerCard-coverInnerItem2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.questionAnswerCard-coverContent2 {
  height: 50%;
  width: 100%;
  /* border: solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.questionAnswerCard-coverContent2 > p {
  font-size: 18px;
  font-weight: 500;
  text-align: justify;
}
#lineId {
  border: 2px solid black;
  height: 250px;
  width: 0%;
  position: absolute;
  left: 0;
}
.questionAnswerCard-coverInnerItem2 > h2 > a {
  color: black;
  text-decoration: none;
}
