.nav5iContainer {
  /* border: solid; */
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.navi5Content {
  /* border: solid; */
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 70px;
  color: #cc2228;
}
.navi5Content > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  gap: 20px;
}
.navi5Content > h2 > a {
  color: #cc2228;
  text-decoration: underline;
  font-weight: bold;
}
.navi5Content > h2 > span {
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .navi5Container {
    /* border: solid; */
    height: 50px;
    width: 100%;
  }
  .navi5Content {
    /* border: solid; */
    height: 50px;
    width: 90%;
    justify-content: flex-start;
    align-items: cente3;
    margin-left: 20px;
  }
  .navi5Content > h2 {
    font-size: 90%;
    font-weight: lighter;
  }
}
@media only screen and (max-width: 990px) and (min-width: 600px) {
  .navi5Container {
    /* border: solid; */
    height: 50px;
    width: 100%;
  }
  .navi5Content {
    /* border: solid; */
    height: 50px;
    width: 500px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .navi5Content > h2 {
    font-size: 20px;
    font-weight: lighter;
  }
}
