.naviContainer {
  /* border: solid; */
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.naviContent {
  /* border: solid; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
  color: #cc2228;
}
.naviContent > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  gap: 20px;
}
.naviContent > h2 > a {
  color: #cc2228;
  text-decoration: underline;
}
.naviContent > h2 > {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  gap: 20px;
}
@media screen and (max-width: 600px) {
  .naviContainer {
    /* border: solid; */
    height: 50px;
    width: 100%;
  }
  .naviContent > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100%;
    gap: 20px;
  }
}
