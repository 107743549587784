.serviceReadContainer {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}
.serviceReadHeadContainer {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.serviceReadHeadContainer > button {
  border: none;
  background-color: white;
  cursor: pointer;
  padding: 30px;
}
.serviceReadHeadContainer > button > h6 {
  font-size: 20px;
  text-decoration: underline;
  font-weight: lighter;
}
.serviceReadContainer > header > h1 {
  margin: 20px;
  font-size: 40px;
  color: var(--customColor);
  display: block;
  font-weight: lighter;
  text-align: center;
}
.serviceReadHeadContainer > ul {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.serviceReadHeadContainer > ul > li {
  width: 90%;
  font-size: 20px;
  list-style: none;
  margin: 10px;
}
.serviceReadHeadContainer > ul > li > a {
  color: var(--customColor);
  text-decoration: none;
}
.serviceReadDisplayContainer {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: none;
  cursor: default;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.serviceReadDisplayContainer > h3 {
  font-size: 25px;
}
.serviceReadDisplayContainer > ul > li > h4 {
  font-size: 20px;
  font-weight: lighter;
}
.serviceReadDisplayContainer > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;

}
.serviceReadDisplayContainer > ul {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.serviceReadDisplayHead {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.serviceReadDisplayHead > h3 {
  width: 90%;
  text-align: start;
  font-size: 20px;
  margin-top: 10px;
}
.openBut{
  color: var(--customColor);
}
.serviceReadDisplayContainer > ul > li {
  /* border: solid blue; */
  width: 90%;
  font-size: 20px;
  list-style: none;
  margin: 10px;
}
.serviceReadDisplayContainer > ul > li > a {
  color: var(--customColor);
  text-decoration: none;

}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .serviceReadContainer {
    /* border: solid red; */
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    text-align: justify;
  }
  .serviceReadHeadContainer > h1 {
    text-align: center;
  }
  .serviceReadDisplayContainer {
    height: 100%;
  }
  .serviceReadDisplayContainer ul > ul > li {
    /* border: solid green; */
    margin: 5%;
  }
}
