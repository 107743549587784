.microBladingCompare {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  margin-top: 20px;
}
.microBladingCompareHead {
  width: 100%;
}
.microBladingCompareHead > h2 {
  text-align: center;
  font-size: 30px;
  width: 100%;
  color: var(--customColor);
}
.MicroBladingCompareMain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}
.imgCompareCardContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.compareSlider {
  outline: none;
  width: 100%;  
  height: 100%;
}
.innerCompareSlider>img {
  border: solid;
  width: 100%;
  object-fit: contain;
}

@media screen and (min-width: 320px) and  (max-width: 768px) {
  .microBladingCompare {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
    margin-top: 20px;
  }
  .imgCompareCardContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
  }
}