.lavelleLocAboutContainer {
  /* border: solid; */
  margin-top: 10%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.lavelleLocAboutContainer > p {
  /* border: solid red; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.lavelleLocAboutContainer > h1 {
  text-align: center;
  font-size: 40px;
  color: var(--customColor);
  font-weight: lighter;
}
.lavelleLocAboutContainer > p > ul > li > h3 {
  text-align: start;
  font-size: 20px;
  color: var(--customColor);
  font-weight: bold;
}
.lavelleLocAboutContainer > p > ul > li {
  list-style: none;
  text-align: justify;
  font-size: 120%;
  margin: 20px;
}
