

.nailCardContainer {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 300vh;
}
.nailCardMain {
  /* border: solid red; */
  position: relative;
  width: 25%;
  margin: 30px;
}
.nailCardWrapper {
  /* border: solid; */
  height: 320px;
  width: 90;
  margin: 15px;
  position: relative;
  overflow: hidden;
}
.nailCardWrapper > img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 600ms ease-in-out;
}
.nailCardWrapper:hover .nailCardImg {
  transform: scale(1.2);
}

.nailCardCover {
  /* border: solid green; */
  height: 100%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  display: none;
}
.nailCardWrapper:hover .nailCardCover {
  display: flex;
}
.nailCardTxtContainer {
  /* border: solid; */
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}
.nailCardHead > h3 {
  font-size: 150%;
  color: white;
}

.nailCardPara {
  font-weight: bold;
  color: white;
  text-align: justify;
}
.nailCardPara > p {
  font-size: 90%;
}
.nailCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}
.nailGoto {
  border: 2px whitesmoke solid;
  display: flex;
  width: 280px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 3;
  bottom: -5px;
  left: 45px;
  background-color: white;
}
.nailGoTo1 {
  margin-left: 20px;
}
.nailGoTo1 > h3 {
  color: var(--customColor);
  font-size: 100%;
}
.nailGoto > .nailGoTo2 {
  margin-right: 20px;
}
.respNailServiceContainer {
  /* border: solid green; */
  height: 200px;
  width: 100%;
  margin: 0px;
  position: relative;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
}
.respNailServiceCardCover {
  /* border: solid blue; */
  height: 100%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.respNailServiceCardTxtContainer {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 0;
}
.respNailServiceCardHead > h3 {
  font-size: 130%;
  color: white;
}

.respNailServiceCardPara {
  font-weight: bold;
  color: white;
  text-align: justify;
  word-spacing: 2px;
  width: 90%;
}
.respNailServiceCardPara > p {
  font-size: 80%;
}
.respNailServiceCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 30px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}

@media screen and (min-width: 320px) {
  .nailCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
    margin-top: 0%;
  }
  .nailCardMain {
    border: solid red;
    position: relative;
    height: 480px;
    max-width: 300px;
    margin: 20px;
  }
  .respNailServiceContainer {
    display: flex;
  }
  .nailCardWrapper {
    border: solid;
    height: 200px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .nailCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }

  .nailCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .nailCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .nailCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .respNailServiceCardPara > p {
    font-size: 90%;
  }

  .nailCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .nailGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 90%;
    height: 35px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -4%;
    left: 15px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 100%;
  }
  .goTo2 {
    margin-right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .nailCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .nailCardMain {
    /* border: solid red; */
    position: relative;
    max-width: 300px;
    height: 460px;
    margin: 30px;
  }
  .respNailServiceContainer {
    display: flex;
  }
  .nailCardWrapper {
    /* border: solid; */
    height: 250px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .nailCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .nailCardWrapper:hover .nailCardImg {
    transform: scale(1.2);
  }

  .nailCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .nailCardWrapper:hover .nailCardCover {
    display: none;
  }
  .nailCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .nailCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .nailCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .respNailServiceCardPara > p {
    font-size: 90%;
  }
  .nailCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .nailGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 35px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -4%;
    left: 35px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 100%;
  }
  .goTo2 {
    margin-right: 20px;
  }
}
@media screen and (min-width: 990px) {
  .nailCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .nailCardMain {
    /* border: solid red; */
    position: relative;
    margin: 30px;
    min-width: 400px;
    height: 400px;
  }
  .nailCardWrapper {
    /* border: solid; */
    height: 320px;
    width: 90%;
    margin: 15px;
    position: relative;
    overflow: hidden;
  }
  .nailCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .nailCardWrapper:hover .nailCardImg {
    transform: scale(1.2);
  }

  .nailCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .nailCardWrapper:hover .nailCardCover {
    display: flex;
  }
  .nailCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .nailCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .nailCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .nailCardPara > p {
    font-size: 90%;
  }
  .nailCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .nailGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -5px;
    left: 35px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 100%;
  }
  .goTo2 {
    margin-right: 20px;
  }
  .respNailServiceContainer {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .nailCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .nailCardMain {
    /* border: solid red; */
    position: relative;
    margin: 30px;
    min-width: 400px;
    height: 400px;
  }
  .nailCardWrapper {
    /* border: solid; */
    height: 320px;
    width: 90%;
    margin: 15px;
    position: relative;
    overflow: hidden;
  }
  .nailCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .nailCardWrapper:hover .nailCardImg {
    transform: scale(1.2);
  }

  .nailCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .nailCardWrapper:hover .nailCardCover {
    display: flex;
  }
  .nailCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .nailCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .nailCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .nailCardPara > p {
    font-size: 90%;
  }
  .nailCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .nailGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -5px;
    left: 40px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 100%;
  }
  .goTo2 {
    margin-right: 20px;
  }
}
