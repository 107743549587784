.academyWhyChoose-container {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.academyWhyChoose-headContainer {
  /* border: solid green; */
  width: 100%;
  height: 100%;
}
.academyWhyChoose-headContainer > h2 {
  color: var(--charcoalGray);
  text-align: center;
  font-size: 40px;
}
.academyWhyChoose-mainContainer {
  /* border: solid blue; */
  width: 80%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.academyWhyChoose-mainCard {
  width: 268px;
  height: 268px;
  background-color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 20px;
  padding: 10px;
}
.academyWhyChoose-mainCard > img {
  width: 60px;
  height: 60px;
}
.academyWhyChoose-mainCard > h3 {
  text-align: center;
}

.academyWhyChoose-mainCard > P {
  font-size: 12px;
  text-align: center;
}
.academyWhyChoose-mainContainer > p {
  font-size: 18px;
  text-align: center;
  color: var(--charcoalGray);
  font-weight: 500;
}
.academyWhyChoose-mainContainer > a > button {
  border: none;
  width: 200px;
  height: 50px;
  color: white;
  background-color: var(--customColor);
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
}
