.botoxBenefitsContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
}
.botoxBenefitsContainer > h2 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
  font-size: 30px;
}
.botoxBenefitsContainer > h3 {
  color: var(--customColor);
  width: 100%;
  text-align: start;
  font-size: 25px;
}
.botoxBenefitsContainer > h4 {
  color: var(--customColor);
  width: 100%;
  text-align: start;
  font-size: 25px;
}
.botoxBenefitsContainer > p {
  width: 100%;
  font-size: 20px;
  text-align: justify;
}
.botoxBenefitsContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.botoxBenefitsContainer > li > h6 {
  color: var(--customColor);
  font-size: 20px;
}

.botoxBenefitsContainer > li {
  /* border: solid; */
  width: 100%;
  font-size: 20px;
  text-align: start;
  list-style: none;
}
.botoxBenefitsContainer > li > span {
  /* border: solid; */
  color: var(--customColor);
}
.botoxBenefitsContainer > li > a {
  text-decoration: none;
  color: var(--customColor);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .botoxBenefitsContainer > h2 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .botoxBenefitsContainer > h3 {
    color: var(--customColor);
    width: 100%;
    text-align: start;
    font-size: 20px;
  }
}
