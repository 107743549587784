.hairSalonLocationWrapper {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.hairSalonLocationContent {
  /* border: solid blue; */
  margin-top: 20px;
  width: 90%;
}
.hairSalonLocationTitle {
  /* border: solid green; */
  text-align: center;
}
.hairSalonLocationTitle > h2 {
  color: var(--customColor);
  font-size: 30px;
}
.hairSalonLocationDesp {
  /* border: solid; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.hairSalonLocationDesp > p {
  font-size: 20px;
  width: 100%;
}
.hairSalonLocationWelcomeHead {
  /* border: solid green; */
  margin-top: 30px;
  text-align: center;
}
.hairSalonLocationWelcomeHead > h2 {
  font-size: 25px;
  color: var(--customColor);
}
.hairSalonLocationWelcomePara {
  font-size: 20px;
  margin-top: 10px;
}
.hairSalonLocationWelcomePara > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.hairSalonLocationService {
  margin-top: 20px;
}
.hairSalonLocationService > h2 {
  color: var(--customColor);
  font-size: 25px;
}
.hairSalonLocationServiceList > ol > li {
  margin: 20px;
  font-size: 20px;
}
.hairSalonLocationServiceList > ol > li > span {
  color: #cc2228;
  font-weight: bold;
}
.hairSalonLocationWhyList > ol > li {
  margin: 20px;
  list-style: none;
  font-size: 20px;
}
.hairSalonLocationWhyList > ol > li > span {
  color: #cc2228;
  font-weight: bold;
}
.hairSalonLocationWhy > h2 {
  font-size: 25px;
  color: var(--customColor);
}
.hairSalonLocationBookPara {
  /* border: solid; */
  margin-top: 10px;
  font-size: 20px;
}
.hairSalonLocationBook > h2 {
  color: var(--customColor);
  font-size: 25px;
}
.hairSalonLocationBookPara > p > a {
  color: #cc2228;
  text-decoration: none;
}
@media (min-width: 320px) and (max-width: 768px) {
  .hairSalonLocationTitle > h2 {
    font-size: 25px;
  }
}
