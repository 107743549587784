.innerCardContainer {
  border: 2px solid;
  width: 30%;
  height: 250px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px;
}
.innerCardContent {
  /* border: solid blue; */
  width: 90%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.innerCardContent > h2 {
  color: var(--customColor);
  font-size: 20px;
}

.innerCardContent > h2 > span {
  color: var(--customColor);
  font-size: 15px;
  text-align: justify;
}
.innerCardContent > h2 > span > span {
  font-weight: lighter;
}
.innerCardContent > p {
  text-align: justify;
}
.innerCardGoto {
  border: solid var(--customColor);
  margin-bottom: -50px;
  height: 40px;
  width: 90%;
  border-radius: 5px;
  background-color: var(--customColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.innerCardGoto > a {
  width: 100%;
  text-decoration: none;
}
.innerCardGoto > a > p {
  /* border: solid black; */
  font-size: 20px;
  color: white;
  margin-left: 10px;
}
.innerCardGoto:hover {
  border: solid;
  color: var(--customColor);
  background-color: white;
}
.innerCardGoto:hover .innerCardPara {
  /* border: solid; */
  color: var(--customColor);
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .innerCardContainer {
    border: 2px solid;
    min-width: 300px;
    height: 250px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .innerCardContainer {
    border: solid;
    height: 100%;
    margin-top: 50px;
  }

  .innerCardContainer {
    border: 2px solid;
    width: 40%;
    height: 210px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px;
  }
  .innerCardContent {
    /* border: solid blue; */
    width: 90%;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  .innerCardContent > h2 {
    font-size: 100%;
    text-align: center;
  }

  .innerCardContent > h2 > span {
    color: var(--customColor);
  }
  .innerCardContent > h2 > span > span {
    font-weight: lighter;
  }
  .innerCardGoto {
    /* border: solid; */
    bottom: -20px;
    height: 40px;
    width: 90%;
    border-radius: 5px;
    background-color: var(--customRed);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .innerCardGoto > a {
    width: 100%;
  }
  .innerCardGoto > a > p {
    font-size: 100%;
    color: white;
    margin-left: 10px;
  }
  .innerCardGoto:hover {
    border: solid;
    color: var(--customColor);
    background-color: white;
  }
  .innerCardGoto:hover p {
    color: var(--customColor);
  }
}
