.headSpaImgContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headSpaImgContainer > img {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .headSpaImgContainer {
    /* border: solid red; */
    width: 100%;
  }
}
