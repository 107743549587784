.nearContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

}
.nearHeadContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px;

}
.nearHeadContainer > h1 {
  font-size: 30px;
  color: var(--customColor);
}
.nearHeadContainer > h2 {
  font-size: 30px;
  color: var(--customColor);
}
.nearHeadContainer > p {
  font-size: 20px;
}
.nearHeadContainer > p > a {
  text-decoration: none;
  color: #cc2228;
}
.compContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 50px;
}
.compContainer > p {
  font-size: 20px;
}
.compContainer > p > a {
  text-decoration: none;
  color: #cc2228;
}
.compContainer > h2 {
  color: #cc2228;
  font-size: 25px;
  text-align: start;
  color: var(--customColor);
}
.compContainer > h3 {
  color: #cc2228;
  font-size: 20px;
  text-align: start;
  color: var(--customColor);
}
