
.serviceWhyContainer {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: white;
  background-image: url("./../../../Images/HomeBg/WhyBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.serviceWhyHeadContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 5%;
  margin-top: 20px;
}
.serviceWhyHeadContainer > h6 {
  font-size: 30px;
  color: var(--charcoalGray);
}

.serviceWhyUnderline {
  border: 2px solid;
  color: var(--charcoalGray);
  width: 5%;
}
.serviceWhyMainContainer {
  /* border: solid green; */
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.serviceWhyCardContainer {
  /* border: solid; */
  height: 150px;
  width: 130px;
  margin: 3%;
}
.serviceWhyCardContent1 {
  /* border: solid green; */
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.serviceWhyCardContent1 > img {
  width: 50%;
  height: 50%;
}

.serviceWhyCardContent2 {
  /* border: solid blue; */
  height: 20%;
}
.serviceWhyCardContent2 > p {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100%;
  font-weight: bold;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .serviceWhyContainer {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    margin-top: 20%;
  }
  .serviceWhyUnderline {
    border: 2px solid;
    color: var(--customColor);
    width: 20%;
  }
  .serviceWhyMainContainer {
    /* border: solid green; */
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .serviceWhyCardContainer {
    /* border: solid; */
    height: 150px;
    width: 130px;
    margin: 3%;
  }
  .serviceWhyHeadContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 5%;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .serviceWhyContainer {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    margin-top: 15%;
  }
  .serviceWhyUnderline {
    border: 2px solid;
    color: var(--customColor);
    width: 10%;
  }
  .serviceWhyMainContainer {
    /* border: solid green; */
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .serviceWhyCardContainer {
    /* border: solid; */
    height: 150px;
    width: 130px;
    margin: 3%;
  }
  .serviceWhyHeadContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 5%;
  }
}
