

.partnerContainer {
  /* border: solid green; */
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  /* margin-top: 100px; */
  position: relative;
  background-color: white;

}
.partnerContainer > img {
  /* border: solid; */
  height: 100%;
  width: 60%;
  position: absolute;
  right: 0;
}
#partnerContent1 {
  /* border: solid yellowgreen; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.partnerItem1 > h5 {
  font-size: 30px;
  color: var(--customColor);
}
.partnerUnderline {
  border: 2px solid;
  width: 75px;
  color: var(--customColor);
}
#partnerContent2 {
  /* border: solid teal; */
  height: 50%;
  width: 80%;
}
.partnerSlideContainer {
  /* border: solid red; */
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.partnerSlideContainer > img {
  /* border: solid; */
  height: 85%;
  width: 90%;
  margin-top: 15px;
  margin-left: 10px;
}
#estee > img {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-left: 0;
}

#keraste > img {
  width: 70%;
  height: 25%;
  margin-top: 30%;
  margin-left: 15%;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .partnerContainer {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    padding: 20px;
  }

  #partnerContent2 {
    /* border: solid red; */
    height: 100%;
    width: 70%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  .partnerSlideContainer {
    /* border: solid blue; */
    width: 100%;
  }

  .partnerSlideContainer > img {
    /* border: solid blue; */
    height: 100%;
    width: 90%;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  #estee > img {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-left: 0;
  }

  #keraste > img {
    width: 70%;
    height: 25%;
    margin-top: 30%;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 990px) and (min-width: 768px) {
  .partnerContainer {
    /* border: solid green; */
    height: 350px;
    width: 100%;
  }

  #partnerContent2 {
    /* border: solid red; */
    height: 200px;
    width: 90%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  .partnerSlideContainer {
    /* border: solid blue; */
    width: 100%;
  }

  .partnerSlideContainer > img {
    /* border: solid blue; */
    height: 100%;
    width: 90%;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  #estee > img {
    width: 120%;
    height: 100%;
    margin-top: -5px;
    margin-left: 0;
    object-position: -10px;
  }

  #keraste > img {
    width: 70%;
    height: 25%;
    margin-top: 45%;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .partnerContainer {
    /* border: solid green; */
    height: 350px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
  }
  #partnerContent1 {
    /* border: solid yellowgreen; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .partnerUnderline {
    border: 2px solid;
    width: 75px;
    color: var(--customColor);
  }
  #partnerContent2 {
    /* border: solid teal; */
    height: 50%;
    width: 90%;
  }
  .partnerSlideContainer {
    /* border: solid red; */
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .partnerSlideContainer > img {
    /* border: solid; */
    height: 85%;
    width: 90%;
    margin-top: 15px;
    margin-left: 10px;
  }
  #estee > img {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-left: 0;
  }

  #keraste > img {
    width: 70%;
    height: 25%;
    margin-top: 40%;
    margin-left: 15%;
  }
}
