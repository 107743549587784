

.spaCardContainer {
  /* border: solid blue; */
  width: 100%;
  height: 300vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.spaCardMain {
  /* border: solid red; */
  position: relative;
  width: 25%;
  margin: 20px;
}
.spaCardWrapper {
  /* border: solid; */
  height: 320px;
  width: 90%;
  margin: 15px;
  position: relative;
  overflow: hidden;
}
.spaCardWrapper > img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 600ms ease-in-out;
}
.spaCardWrapper:hover .spaCardImg {
  transform: scale(1.2);
}

.spaCardCover {
  /* border: solid green; */
  height: 100%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  display: none;
}
.spaCardWrapper:hover .spaCardCover {
  display: flex;
}
.spaCardTxtContainer {
  /* border: solid; */
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}
.spaCardHead > h3 {
  font-size: 150%;
  color: white;
}

.spaCardPara {
  font-weight: bold;
  color: white;
  text-align: justify;
}
.spaCardPara > p {
  font-size: 90%;
}
.spaCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}
.spaGoto {
  border: 2px whitesmoke solid;
  display: flex;
  width: 280px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 3;
  bottom: -5px;
  left: 45px;
  background-color: white;
}
.goTo1 {
  margin-left: 20px;
}
.goTo1 > h3 {
  color: var(--customColor);
  font-size: 20px;
}
.goTo2 {
  margin-right: 20px;
}
.respSpaServiceContainer {
  /* border: solid green; */
  height: 300px;
  width: 100%;
  margin: 0px;
  position: relative;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
}
.respSpaServiceCardCover {
  /* border: solid blue; */
  height: 150%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.respSpaServiceCardTxtContainer {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 0;
}
.respSpaServiceCardHead > h3 {
  font-size: 160%;
  color: white;
  text-align: center;
}

.respSpaServiceCardPara {
  font-weight: bold;
  color: white;
  text-align: justify;
  word-spacing: 2px;
  width: 90%;
}

.respSpaServiceCardBut > a > button {
  /* border: solid; */
  width: 200px;
  height: 30px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: none;
  color: var(--customColor);
}
@media screen and (min-width: 320px) {
  .spaCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .spaCardMain {
    /* border: solid red; */
    position: relative;
    width: 90%;
    margin: 10%;
  }
  .respSpaServiceContainer {
    display: flex;
  }
  .spaCardWrapper {
    /* border: solid; */
    height: 200px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .spaCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .spaCardWrapper:hover .spaCardImg {
    transform: scale(1.2);
  }

  .spaCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .spaCardWrapper:hover .spaCardCover {
    display: none;
  }
  .spaCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .spaCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .spaCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .respSpaServiceCardPara > p {
    font-size: 90%;
  }
  .spaCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .spaGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 90%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -4%;
    left: 15px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 20px;
  }
  .goTo2 {
    margin-right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .spaCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .spaCardMain {
    /* border: solid red; */
    position: relative;
    width: 40%;
    margin: 5%;
  }
  .respSpaServiceContainer {
    display: flex;
  }
  .spaCardWrapper {
    /* border: solid; */
    height: 250px;
    width: 100%;
    margin: 0px;
    position: relative;
    overflow: hidden;
  }
  .spaCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .spaCardWrapper:hover .spaCardImg {
    transform: scale(1.2);
  }

  .spaCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .spaCardWrapper:hover .spaCardCover {
    display: none;
  }
  .spaCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .spaCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .spaCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .respSpaServiceCardPara > p {
    font-size: 90%;
  }
  .spaCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .spaGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -4%;
    left: 35px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 20px;
  }
  .goTo2 {
    margin-right: 20px;
  }
}
@media screen and (min-width: 990px) {
  .spaCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
  }
  .spaCardMain {
    /* border: solid red; */
    position: relative;
    width: 35%;
    margin: 5%;
  }
  .spaCardWrapper {
    /* border: solid; */
    height: 320px;
    width: 90%;
    margin: 15px;
    position: relative;
    overflow: hidden;
  }
  .spaCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .spaCardWrapper:hover .spaCardImg {
    transform: scale(1.2);
  }

  .spaCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .spaCardWrapper:hover .spaCardCover {
    display: flex;
  }
  .spaCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .spaCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .spaCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .spaCardPara > p {
    font-size: 90%;
  }
  .spaCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .spaGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -5px;
    left: 35px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 20px;
  }
  .goTo2 {
    margin-right: 20px;
  }
  .respSpaServiceContainer {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .spaCardContainer {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
  }
  .spaCardMain {
    /* border: solid red; */
    position: relative;
    width: 35%;
    margin: 5%;
  }
  .spaCardWrapper {
    /* border: solid; */
    height: 320px;
    width: 90%;
    margin: 15px;
    position: relative;
    overflow: hidden;
  }
  .spaCardWrapper > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 600ms ease-in-out;
  }
  .spaCardWrapper:hover .spaCardImg {
    transform: scale(1.2);
  }

  .spaCardCover {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    background-color: var(--customRed);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    display: none;
  }
  .spaCardWrapper:hover .spaCardCover {
    display: flex;
  }
  .spaCardTxtContainer {
    /* border: solid; */
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .spaCardHead > h3 {
    font-size: 150%;
    color: white;
  }

  .spaCardPara {
    font-weight: bold;
    color: white;
    text-align: justify;
  }
  .spaCardPara > p {
    font-size: 90%;
  }
  .spaCardBut > a > button {
    /* border: solid; */
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    border: none;
    color: var(--customColor);
  }
  .spaGoto {
    border: 2px whitesmoke solid;
    display: flex;
    width: 80%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    bottom: -5px;
    left: 40px;
    background-color: white;
  }
  .goTo1 {
    margin-left: 20px;
  }
  .goTo1 > h3 {
    color: var(--customColor);
    font-size: 20px;
  }
  .goTo2 {
    margin-right: 20px;
  }
}
