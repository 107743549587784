

.hairServiceImgContainer {
  /* border: solid red; */
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
/* .servicePageImgContainer > img {
    height: 80%;
    width: 90%;
    position: relative;
    z-index: 1;
  } */
.hairImgCover {
  width: 90%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.595);
  position: relative;
  z-index: 1;
  /* background: url("https://videocdn.cdnpk.net/joy/content/video/free/video0460/large_preview/_import_60d2f41e3bcdc1.08424385.mp4");
    background-repeat: no-repeat;
    background-size: cover; */
}
.hairImgCover > video {
  /* border: red solid; */
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.hairCover {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.595);
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.hairOverCoverTxt {
  /* border: solid; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hairOverCoverTxt > h2 {
  font-size: 50px;
  font-weight: lighter;
  color: white;
  text-align: center;
}
.hairCoverUnderline {
  border: 2px solid;
  width: 100px;
  color: var(--customColor);
}
.hairOverCoverTxt > p {
  border: solid 10px transparent;
  text-align: center;
  width: 95%;
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .hairServiceImgContainer {
    /* border: solid red; */
    height: 70vh;
    width: 100%;
  }
  .hairOverCoverTxt > p {
    width: 100%;
    letter-spacing: 1px;
    font-size: 80%;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .hairServiceimgContainer {
    /* border: solid red; */
    height: 70vh;
    width: 100%;
  }
  .overCoverTxt > p {
    width: 100%;
    letter-spacing: 1px;
    font-size: 30px;
  }
}
