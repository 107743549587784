.hairTreatmentLandingCollection {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  background-color: white;
}
.hairTreatmentCollectionWrapper {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
}
.hairTreatmentCollectionHead {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.hairTreatmentCollectionHead > h2 {
  width: 100%;
  text-align: center;
  font-size: 50px;
  color: var(--customColor);
  font-weight: 400;
}
.hairTreatmentCollectionHead > p {
  width: 100%;
  text-align: center;
  font-size: 25px;
}
.hairTreatmentCollectionContent {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 10px;
}
.hairTreatmentCollectionCard {
  /* border: solid green; */
  width: 25%;
  height: 500px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 5px rgb(175, 175, 175);
}
.hairTreatmentCollectionCardContent {
  /* border: solid brown; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
#hairTreatmentCollectionCardContent1 {
  height: 40%;
}
#hairTreatmentCollectionCardContent2 {
  height: 40%;
  padding: 10px;
  /* background-color: var(--customColor); */
}
#hairTreatmentCollectionCardContent3 {
  height: 20%;
  /* background-color: black; */
}
.hairTreatmentCollectionCardContent > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hairTreatmentCollectionCardItem {
  /* border: solid white; */
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
}
.hairTreatmentCollectionCardContent > p {
  width: 90%;
  text-align: center;
  color: black;
}
.hairTreatmentCollectionCardItem > h3 {
  color: white;
  font-weight: 400;
}
.hairTreatmentCollectionCardItem:hover {
  background-color: var(--customColor);
}
.hairTreatmentCollectionCardContent > h2 {
  color: black;
  font-weight: 400;
}
.hairTreatmentCollectionCardContent > button {
  width: 35%;
  height: 40%;
  font-size: 15px;
  background-color: white;
  border: none;
  transition: 300ms ease-in-out;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 400;
  background-color: var(--customColor);
  color: white;
}
.hairTreatmentCollectionCardContent > button > a {
  width: 35%;
  height: 40%;
  font-size: 15px;
  border: none;
  transition: 300ms ease-in-out;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 400;
  text-decoration: none;
  color: white;
}
.hairTreatmentCollectionCardContent > button:hover {
  border: 2px solid var(--customColor);
  background-color: white;
}
.hairTreatmentCollectionCardContent
  > button:hover
  .hairTreatmentCollectionLink {
  color: var(--customColor);
}
.hairTreatmentCollectionImg {
  border: solid;
  width: 100%;
  height: 100%;
}
.hairTreatmentCollectionImg > img {
  width: 100%;
  height: 100%;
  object-position: 0 10px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hairTreatmentCollectionWrapper {
    /* border: solid red; */
    padding: 10px;
  }
  .hairTreatmentCollectionContent {
    /* border: solid blue; */
    flex-direction: column;
  }
  .hairTreatmentCollectionCard {
    /* border: solid green; */
    min-width: 300px;
    height: 500px;
  }
}
