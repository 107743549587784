.academyHeader-container {
  position: relative;
  overflow: hidden; /* Ensure waves stay within the container */
  text-align: center; /* Center content */
  height: 650px;
  width: 100%;
}
.academyHeader-cover {
  /* border-bottom:solid white; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.academyHeader-cover > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: rotateY(-180deg);
}
.academyHeader-content {
  /* border: solid brown; */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3a313166;
  padding: 20px;
}
.wave {
  /* border: solid red; */
  position: absolute;
  background-color: transparent;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%; /* Adjust wave height as needed */
  z-index: 1;
  pointer-events: none; /* Prevent interference with user interactions */
}

.wave-top {
  /* border: solid green; */
  top: 0;
  transform: rotate(180deg); /* Flip the wave for the top */
}

.wave-bottom {
  bottom: 0;
}
.academyHeader-contentText {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.academyHeader-contentText > h2 {
  color: white;
  font-size: 40px;
}
.academyHeader-contentText > h4 {
  color: white;
  font-size: 30px;
  font-weight: 500;
}
.academyHeader-contentText > p {
  font-size: 17px;
  color: white;
  text-align: justify;
  width: 80%;
}
.academyHeader-contentText > a > button {
  border: none;
  width: 200px;
  height: 50px;
  color: white;
  background-color: var(--customColor);
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
}
@media screen and (min-width:320px) and (max-width:768px) {
  .academyHeader-contentText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
  }
  .academyHeader-contentText > p {
    font-size: 17px;
    color: white;
    text-align: justify;
    width: 90%;
  }
}
@media screen and (min-width:789px) and (max-width:990px) {
  .academyHeader-contentText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
  }
  .academyHeader-contentText > p {
    font-size: 17px;
    color: white;
    text-align: justify;
    width: 90%;
  }
}