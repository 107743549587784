* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


:root {
  /* --customColor: #cc2228; */
  --customColorTrans: #cc222897;
  --customColor: #800020;
  --charcoalGray: #333333;
  --softGold: #d4af37;
  --mutedTaupe: #b8a08c;
  --lightBeige: #f5f5dc;
  --warmGray: #a8a8a8;
  --deepBurgundy: #800020;
  --slateBlue: #6a7ba2;
}
@font-face {
  font-family: "customFont";
  src: url("./Font/zapf_renaissance_book.ttf") format("truetype");
}
