
.hairSalonContainer {
  /* border: solid red; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  padding: 20px;
}
.hairSalonHeadContainer {
  /* border: solid; */
  height: 70px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--customColor);
}
.hairSalonHeadContainer > h2 {
  font-size: 30px;
  text-align: center;
  color: var(--customColor);
}
.hairSalonUnderline {
  border: 2px solid;
  width: 60px;
  color: var(--customColor);
}
.hairSalonParaContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.hairSalonParaContainer > p {
  /* border: solid blue; */
  text-align: center;
  width: 90%;
  font-size: 20px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .hairSalonContainer {
    /* border: solid red; */
    height: 30%;
    width: 100%;
    gap: 20px;
    margin-top: 50px;
  }
  .hairSalonUnderline {
    border: 2px solid;
    width: 100px;
  }
  .hairSalonHeadContainer > h2 {
    font-size: 25px;
  }
  .hairSalonParaContainer > p {
    /* border: solid blue; */
    text-align: center;
    width: 90%;
    font-size: 100%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .hairSalonHeadContainer > h2 {
    font-size: 30px;
  }
}
