.innerNaviContainer {
  /* border: solid; */
  height: 100px;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.innerNaviContent {
  /* border: solid; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cc2228;
}
.innerNaviContent > h2 > a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100%;
  font-weight: bold;
  text-decoration: underline;
  color: #cc2228;

  gap: 10px;
}
.innerNaviContent > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100%;
  font-weight: bold;
  gap: 10px;
}
.innerNaviContent > h2 > span {
  color: var(--customColor);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .innerNaviContainer {
    /* border: solid; */
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .innerNaviContent {
    /* border: solid; */
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    color: #cc2228;
  }
  .innerNaviContent > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 90%;
    gap: 10px;
  }
}
@media screen and (min-width: 768px) {
  .innerNaviContainer {
    /* border: solid; */
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innerNaviContent {
    /* border: solid; */
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    color: #cc2228;
  }
  .innerNaviContent > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90%;
    gap: 10px;
  }
}

@media screen and (min-width: 990px) {
  .innerNaviContainer {
    /* border: solid; */
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innerNaviContent {
    /* border: solid; */
    height: 50px;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
    color: #cc2228;
  }
  .innerNaviContent > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90%;
    gap: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .innerNaviContainer {
    /* border: solid; */
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innerNaviContent {
    /* border: solid; */
    height: 50px;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
    color: #cc2228;
  }
  .innerNaviContent > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100%;
    gap: 10px;
  }
}
