.hairColorStreakLandingMission {
    /* border: solid red; */
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: black;
  }
  .hairColorStreakMissionContent1 {
    /* border: solid blue; */
    width: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .hairColorStreakMissionContent2 {
    /* border: solid blue; */
    width: 50%;
  }
  .hairColorStreakMissionItem {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
    width: 70%;
  }
  .hairColorStreakMissionItem > h3 {
    font-size: 50px;
    font-weight: 400;
    color: var(--customColor);
  }
  .hairColorStreakMissionItem > p {
    font-size: 18px;
    color: white;
  }
  .hairColorStreakMissionItem > p > span {
    font-size: 25px;
    color: white;
  }
  
  .hairColorStreakMissionItem > a > button {
    font-size: 15px;
    padding: 10px;
    border: black solid;
    border-radius: 10px;
    background-color: var(--customColor);
    color: white;
    cursor: pointer;
  }
  .hairColorStreakMissionItem > a > button:hover {
    border: solid var(--customColor);
    background-color: white;
    color: var(--customColor);
  }
  .hairColorStreakLandingMissionMain {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  .hairColorStreakMissionCardWrapper {
    /* border: solid white; */
    width: 40%;
    height: 250px;
    transform-style: preserve-3d;
    transition: 300ms ease-in-out;
    position: relative;
  }
  .hairColorStreakLandingMissionCard {
    /* border: solid white; */
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 300ms ease-in-out;
    position: relative;
  }
  .hairColorStreakLandingMissionCard > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hairColorStreakMissionCardWrapper:hover .hairColorStreakLandingMissionCard {
    transform: rotateY(180deg);
  }
  
  .imgBackFace {
    /* border: solid red; */
    transform: rotateY(180deg);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(45, 52, 54, 1),
      rgba(0, 0, 0, 1) 35%,
      rgba(45, 52, 54, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    backface-visibility: hidden;
  }
  .imgBackFace:hover {
    transform: rotateY(180deg);
  }
  .imgBackFace > h3 {
    font-size: 20px;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .hairColorStreakLandingMission {
      /* border: solid red; */
      flex-direction: column;
      padding: 10px;
    }
    .hairColorStreakMissionContent1 {
      /* border: solid blue; */
      width: 100%;
      padding: 0px;
    }
    .hairColorStreakMissionContent2 {
      /* border: solid blue; */
      width: 100%;
    }
    .hairColorStreakMissionItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 50px;
      width: 90%;
    }
    .hairColorStreakMissionCardWrapper {
      /* border: solid white; */
      min-width: 300px;
      height: 250px;
    }
    .hairColorStreakMissionItem > p {
      text-align: justify;
    }
    .hairColorStreakMissionItem > p > span {
      text-align: justify;
    }
  }
  