.botoxLandingProducts {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.botoxLandingProducts > video {
  width: 100%;
  height: 100%;
  border: none;
}
.botoxLandingProductsHead {
  /* border: solid blue; */
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.botoxProductsHeadContent {
  /* border: solid green; */
  border-top: 5px solid var(--customColor);
  border-left: 5px solid var(--customColor);
  border-right: 5px solid black;
  border-bottom: 5px solid black;
  width: 600px;
  height: fit-content;
  margin-left: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}
.botoxProductsHeadContent > img {
  width: 20%;
  height: 100%;
}
.botoxProductsHeadContent > h3 {
  font-size: 50px;
  text-align: center;
  width: 100%;
  -webkit-text-stroke: 1px var(--customColor);
  color: white;
  transform: scale(1.2);
}
.paraTxt1 {
  font-size: 20px;
  width: 80%;
  text-align: center;
}
.paraTxt2 {
  font-size: 25px;
  width: 55%;
  text-align: start;
  font-weight: 500;
}
.paraTxt3 {
  text-align: center;
  color: var(--customColor);
  font-weight: bold;
  font-size: 40px;
  width: 80%;
}
.botoxProductsHeadContent > a > button {
  border:solid white;
  width: 100%;
  background-color: var(--customColor);
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}
.botoxProductsHeadContent > a > button:hover{
  border: solid var(--customColor);
  background-color:white;
color: var(--customColor);
}
.redTxt {
  color: var(--customColor);
}
.blueTxt {
  color: rgb(0, 0, 215);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .botoxLandingProducts {
    /* border: solid red; */
    width: 100%;
    flex-direction: column;
    padding: 10px;
  }
  .botoxLandingProductsHead {
    /* border: solid blue; */
    position: static;
    min-width:300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .botoxProductsHeadContent {
    width: 95%;
    height: 100%;
    margin-left: 0px;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }
  .paraTxt2 {
    font-size: 25px;
    width: 100%;
    text-align: center;
    font-weight: 500;
  }
  .paraTxt3 {
    text-align: start;
    color: var(--customColor);
    font-weight: bold;
    font-size: 30px;
    width: 80%;
  }
}
