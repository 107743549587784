.olaplexLandingWork {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
}
.olaplexLandingWorkHead {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.olaplexLandingWorkHead > h2 {
  font-size: 30px;
  color: var(--customColor);
}
.olaplexLandingWorkMain {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: wrap;
}
.olaplexLandingWorkCard {
  /* border: solid green; */
  width: 25%;
  height: 500px;
  position: relative;
  overflow: hidden;
}
.olaplexLandingWorkCard > img {
  width: 100%;
  height: 100%;
}
.olaplexLandingWorkCardCover {
  /* border: solid red; */
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  transform: translateX(-100%);
  transition: 300ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.596);
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}
.olaplexLandingWorkCard:hover .olaplexLandingWorkCardCover {
  transform: translateX(0%);
}
.olaplexLandingWorkCardUnderline {
  border: 2px white solid;
  width: 20%;
}
.olaplexLandingWorkCardCover > h3 {
  font-size: 40px;
  color: white;
  width: 100%;
  font-weight: 400;
}
.olaplexLandingWorkCardCover > p {
  font-size: 20px;
  color: white;
  text-align: justify;
}
.olaplexLandingWorkCardArrow {
  /* border: solid wheat; */
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px;
}
.olaplexArrow {
  font-size: 30px;
  color: white;
}
#olaplexWorkCardStatic {
  background-color: black;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.olaplexWorkCardStaticContent {
  /* border: solid green; */
  height: 70%;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}
.olaplexWorkCardStaticContent > h3 {
  font-size: 40px;
  color: white;
  width: 100%;
  font-weight: 400;
}
.olaplexWorkCardStaticContent > p {
  font-size: 20px;
  color: white;
  text-align: justify;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .olaplexLandingWork {
    /* border: solid red; */
    padding: 10px;
  }
  .olaplexLandingWorkMain {
    /* border: solid blue; */
    flex-direction: column;
  }
  .olaplexLandingWorkCard {
    /* border: solid green; */
    width: 100%;
    height: 500px;
  }
}
