.olaplexLandingProducts {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .olaplexLandingProducts > img {
    width: 100%;
    height: 100%;
  }
  .olaplexLandingProductsHead {
    /* border: solid blue; */
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .olaplexProductsHeadContent {
    /* border: solid green; */
    border-top: 5px solid var(--customColor);
    border-left: 5px solid var(--customColor);
    border-right: 5px solid black;
    border-bottom: 5px solid black;
  
    width: 600px;
    height: 400px;
    margin-left: 50px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
  }
  .olaplexProductsHeadContent > h3 {
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }
  .olaplexProductsHeadContent > p {
    font-size: 25px;
    width: 80%;
    text-align: center;
  }
  .olaplexProductsHeadContent > a > button {
    border: none;
    width: 150px;
    height: 50px;
    background-color: var(--customColor);
    font-size: 17px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .olaplexLandingProducts {
      /* border: solid red; */
      width: 100%;
      flex-direction: column;
    }
    .olaplexLandingProductsHead {
      /* border: solid blue; */
      position: static;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .olaplexProductsHeadContent {
      width: 100%;
      height: 100%;
      margin-left: 0px;
      flex-direction: column;
      gap: 30px;
      padding: 10px;
    }
  }
  