.returnContainer {
  /* border: solid green; */
  /* height: 60vh; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 30px;

}
.returnHeadContainer {
  /* border: solid red; */
  height: 10%;
  width: 90%;
 
}
.returnHeadContainer>h2 {
    font-size: 40px;
    font-weight: lighter;
    color: var(--customColor);
}

.returnParaContainer {
  /* border: solid blue; */
  width: 90%;
  height: 70%;
  margin-top: 20px;
}
.returnParaContainer > ul > li {
  font-size: 20px;
  list-style: disc;
  margin: 20px
}
