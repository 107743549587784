.navbg {
  background-color: black;
  height: 100px;
}

.scentPhiloPageContent {
  /* border: solid green; */
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.scentPhiloHeadContainer {
  /* border: solid red; */
  height: 200px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.scentPhiloHeadContainer > h1 {
  font-size: 30px;
  /* border: solid; */
  color: transparent;
  background-color: #cc2228;
  background-clip: text;
  filter: grayscale(1);
  transition: 300ms ease-out;
  text-align: center;
}
.philoHead:hover {
  filter: grayscale(0);
}
.philoUnderline {
  border: solid 1px;
  width: 200px;
}
.scentPhiloTxtContainer {
  /* border: solid; */
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.scentPhiloTxtContent1 {
  /* border: solid; */
  width: 50%;
  height: 100%;
}
.scentPhiloTxtContent1 > p {
  font-size: 20px;
  margin: 10px;
}
.scentPhiloTxtContent1 > h2 {
  color: black;
}
.scentPhiloTxtContent1 > h2 > span {
  color: #cc2228;
}
.scentPhiloTxtContent2 {
  /* border: solid blue; */
  width: 30%;
  height: 100%;
}
.scentPhiloTxtContent2 > video {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 320px) and (max-width:768px) {
  .scentPhiloPageContent {
    /* border: solid green; */
    height: 100%;
  }
  .scentPhiloHeadContainer {
    /* border: solid red; */
    height: 100%;

  }
  .scentPhiloHeadContainer > h2 {
    font-size: 40px;
    /* border: solid; */
  }
  .scentPhiloTxtContainer {
    /* border: solid; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .scentPhiloTxtContent1 {
    /* border: solid; */
    width: 90%;
    height: 100%;
  }
  .scentPhiloTxtContent2 {
    /* border: solid blue; */
    width: 90%;
    height: 100%;
  }
}
@media screen and (min-width: 768px) {
  .scentPhiloPageContent {
    /* border: solid green; */
    height: 100%;
  }
  .scentPhiloHeadContainer {
    /* border: solid red; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .scentPhiloHeadContainer > h2 {
    font-size: 40px;
    /* border: solid; */
  }
  .scentPhiloTxtContainer {
    /* border: solid; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .scentPhiloTxtContent1 {
    /* border: solid; */
    width: 90%;
    height: 100%;
  }
  .scentPhiloTxtContent2 {
    /* border: solid blue; */
    width: 90%;
    height: 100%;
  }
}
@media screen and (min-width:990px) {
  .scentPhiloTxtContainer {
    /* border: solid; */
    height: 100%;width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

}
@media screen and (min-width:1200px) {
  .scentPhiloTxtContent2 {
    /* border: solid blue; */
    width: 50%;
    height:300px;
  }
}