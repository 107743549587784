.hairColorStreakLandingMove {
    /* border: solid red; */
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .hairColorStreakLandingMove > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    top: 30px;
    z-index: -1;
  }
  .hairColorStreakMoveContent {
    /* border: solid green; */
    width: 400px;
    height: 400px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hairColorStreakMoveInnerContent {
    border: dotted black;
    width: 95%;
    height: 95%;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .hairColorStreakMoveInnerContent > h2 {
    font-size: 40px;
    color: var(--customColor);
  }
  .hairColorStreakMoveInnerContent > p {
    font-size: 15px;
    width: 80%;
    text-align: center;
  }
  .hairColorStreakMoveInnerContent > a > button {
    width: 100px;
    height: 40px;
    background-color: var(--customColor);
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .hairColorStreakMoveInnerContent > a > button:hover {
    border: solid var(--customColor);
    color: var(--customColor);
    background-color: white;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .hairColorStreakMoveContent {
      /* border: solid green; */
      width: 300px;
      height: 300px;
      border-radius: 100%;
    }
    .hairColorStreakMoveInnerContent > h2 {
      font-size: 25px;
      color: var(--customColor);
      padding: 10px;
    }
    .hairColorStreakLandingMove > img {
      width: 100%;
      height: 100%;
  object-position: -400px;
    }
  }
  