.beautyReadContainer {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  padding: 20px;
}
.beautyReadContainer > h1 {
  margin: 20px;
  font-size: 40px;
  color: var(--customColor);
  display: block;
  font-weight: 400;
  text-align: center;
  width: 80%;
}
.beautyReadHeadContainer {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.beautyReadHeadContainer > ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.beautyReadHeadContainer > ul > li {
  /* border: solid green; */
  width: 90%;
  font-size: 20px;
  list-style: none;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.beautyReadHeadContainer > ul > li > h2 {
  text-align: center;
  width: 100%;
}
.beautyReadHeadContainer > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.beautyReadHeadContainer > ul > li > h4 {
  font-size: 20px;
  font-weight: 400;
}
.beautyReadHeadContainer > button {
  border: none;
  background-color: white;
  cursor: pointer;
}
.beautyReadDisplayContainer {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: none;
  cursor: default;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
.beautyReadDisplayContainer > ul {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.beautyReadDisplayContainer > ul > li {
  /* border: solid blue; */
  width: 90%;
  font-size: 20px;
  list-style: none;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.beautyReadHeadContainer > ul > li > h3 {
  color: var(--customColor);
}

.beautyReadDisplayContainer > ul > li > ul > h3 {
  font-size: 20px;
  font-weight: 400;
  color: var(--customColor);
}
.beautyReadDisplayContainer > ul > li > ul > li {
  list-style: disc;
  margin-left: 30px;
  margin: 20px;
}
.beautyReadDisplayContainer > ul > li > ul > li > span {
  color: var(--customColor);
}

.beautyReadDisplayContainer > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.openBut {
  text-decoration: underline;
  font-weight: 400;
}
.beautyReadDisplayContainer > ul > li > p > span {
  color: var(--customColor);
}
.beautyReadHeadContainer > ul > li > h2 {
  color: var(--customColor);
}
.beautyReadDisplayContainer > ul > li > h3 {
  color: var(--customColor);
}
.beautyReadDisplayContainer > ul > li > ul > h3 {
  color: var(--customColor);
}
.beautyReadDisplayContainer > ul > li > h4 {
  color: var(--customColor);
}
@media screen and (max-width: 600px) {
  .beautyReadContainer {
    /* border: solid red; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    text-align: justify;
  }
  .beautyReadHeadContainer > h2 {
    font-size: 150%;
  }
  .beautyReadDisplayContainer {
    height: 100%;
  }
  .beautyReadDisplayContainer ul > li {
    /* border: solid green; */
    margin: 5%;
    font-size: 100%;
  }
}
