.hairBotoxWho {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  padding: 20px;
}
.hairBotoxWho > h5 {
  font-size: 25px;
  color: var(--customColor);
  text-align: center;
  width: 100%;
}
.hairBotoxWho > p {
  font-size: 20px;
  width: 100%;
  text-align: center;
}
.hairBotoxWho > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.hairBotoxWho > ul > li > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.hairBotoxWho > ul > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}
.hairBotoxWho > ul > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--customColor);
  text-align: start;
}
.hairBotoxWho > ul > li > span > img {
  width: 20px;
  height: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hairBotoxWho {
    /* border: solid red; */
    padding: 5px;
  }
}
