.jakkuruLocPageContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.jakkuruLocPageContent {
  /* border: solid green; */
  width: 100%;
}
