
.olaplexHeadContainer {
  /* border: solid; */
  display: flex;
  height: 10vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-top: 5%;
}
.olaplexUnderline {
  border: 2px solid;
  color: var(--customColor);
  width: 50px;
}
.olaplexCardContainWrapper {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.olaplexCardContainer {
  /* border: solid green; */
  height: 40vh;
  width: 280px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 30px;
  box-shadow: 0px -10px 70px -50px, 0px -10px 70px -50px black;
}
.olaplexCardImg {
  /* border: solid purple; */
  width: 100%;
  height: 70%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.olaplexCardImg > img {
  width: 100%;
  height: 100%;
}
.olaplexCardTxt {
  /* border: solid blue; */
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* background-color: var(--customRed); */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.olaplexCardHead {
  /* border: solid pink; */
  width: 90%;
}
.olaplexCardHead > h2 {
  font-size: 110%;
  text-align: center;
  color: var(--customColor);
}
.olaplexCardTxt > button > a {
  border: none;
  background-color: var(--customRed);
  width: 100%;
  height: 90%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  font-size: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}
.olaplexCardTxt > button {
  border: none;
  background-color: var(--customRed);
  width: 100%;
  height: 50%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  font-size: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.olaplexWhats {
  height: 90%;
  color: #00a884;
}
