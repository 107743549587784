.sahakarLocAboutContainer {
  /* border: solid; */
  margin-top: 10%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.sahakarLocAboutContainer > p {
  /* border: solid red; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.sahakarLocAboutContainer>h1{
text-align: center;
font-size: 40px;
font-weight: lighter;
color: var(--customColor);

}
.sahakarLocAboutContainer > p > ul > li {
  list-style: none;
  text-align: center;
  font-size: 120%;
  margin: 20px; 
}
