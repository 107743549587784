.navbg {
  background-color: black;
  height: 100px;
}

.aboutPgImgContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 20%; */
  height: 100%;
  width: 100%;
  padding: 30px;
}
.aboutPgImgContainer > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.aboutPgHeadContainer {
  /* border: solid blue; */
  margin-top: 5%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 30px;
}
.aboutPgHeadContainer > h1 {
  /* border: solid; */
  color: transparent;
  background-color: #cc2228;
  background-clip: text;
  filter: grayscale(1);
  transition: 300ms ease-out;
  text-align: center;
}
.aboutPgHead:hover {
  filter: grayscale(0);
}
.aboutPgUnderline {
  border: 1px solid;
  margin-top: 30px;
  width: 300px;
}
.aboutPgTxtContainer {
  /* border: solid; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.aboutPgTxtContainer > p {
  text-align: center;
  font-size: 20px;
  margin: 1%;
}
.AboutCarousel {
  /* border: solid; */
  width: 50%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .aboutPgImgContainer {
    /* border: solid red; */
    height: 100%;
    width: 100%;
  }
  .aboutPgTxtContainer {
    /* border: solid; */
    height: 100%;
  }
}
