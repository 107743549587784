.botoxHeadContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}
.botoxHeadContainer > h1 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
}
.botoxHeadContainer > h2 {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.botoxHeadContainer > h3 {
  color: var(--customColor);
  text-align: start;
  width: 100%;
  font-size: 25px;
}
.botoxHeadContainer > h4 {
  color: var(--customColor);
  text-align: start;
  width: 100%;
  font-size: 25px;
}
.botoxHeadContainer > h5 {
  color: var(--customColor);
  text-align: start;
  width: 100%;
  font-size: 25px;
}

.botoxHeadContainer > ul > li {
  list-style: none;
  margin: 10px;
  font-size: 20px;
  text-align: justify;
}
.botoxHeadContainer > ul > li > h6 {
  font-size: 20px;
  text-align: justify;
  color: var(--customColor);
}
.botoxHeadContainer > ul > li > a {
  text-decoration: none;
  color: var(--customColor);
}

.botoxHeadContainer > ul > li > span {
  color: var(--customColor);
  font-weight: bold;
}
.botoxHeadContainer > p {
  text-align: justify;
  font-size: 20px;
}
.botoxHeadContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .botoxHeadContainer > h2 {
    text-align: center;
    font-size: 20px;
  }
  .botoxHeadContainer > h3 {
    text-align: center;
    font-size: 20px;
  }
  .botoxHeadContainer > h4 {
    text-align: center;
    font-size: 20px;
  }
  .botoxHeadContainer > h5 {
    text-align: center;
    font-size: 20px;
  }
}
