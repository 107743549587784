.nailExtensionMaintain {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px;
}
.nailExtensionMaintain > h5 {
  font-size: 25px;
  color: var(--customColor);
  text-align: center;
  width: 100%;
}
.nailExtensionMaintain > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.nailExtensionMaintain > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.nailExtensionMaintain > ul > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.nailExtensionMaintain > ul > li > h6 {
  color: var(--customColor);
  font-size: 20px;
}

.nailExtensionMaintain > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}
