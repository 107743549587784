.microBladingContent {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 20px;
  padding: 20px;
}

.microBladingItem {
  /* border: solid blue; */
  width: 50%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.microBladingItem > img {
  width: 100%;
}
.microBladingItem > h2 {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: var(--customColor);
}
.microBladingItem > p {
  text-align: justify;
  font-size: 20px;
}
.microBladingItem > button {
  width: 100px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: var(--customColor);
}
.microBladingItem > button > a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .microBladingContent {
    /* border: solid red; */
    padding: 5px;
    flex-direction: column;
  }
  .microBladingItem {
    /* border: solid blue; */
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
}
