/* .navContainer {
  border: solid red;
  height: 14vh;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 6;
  gap: 30px;
}
.navContainer1 {
  border: solid;
  height: 14vh;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 6;
  gap: 30px;
} */
.navContainer {
  /* border: solid red; */
  background-color: black;
  position: sticky;
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.logo {
  /* border: green solid; */
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  overflow: hidden;
}
.logo > a {
  width: 100%;
  height: 100%;
  /* border: solid orange; */
}
.logo > a > img {
  width: 100%;
  height: 100%;
}
.menuContainer {
  /* border: solid red; */
  width: 70%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuContent {
  /* border: solid blueviolet; */
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 45px;
  align-items: center;
  position: relative;
  margin-right: 30px;
}
.menuContent > li {
  /* border: solid blueviolet; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  list-style: none;
  font-size: 18px;
  font-weight: lighter;
  letter-spacing: 1px;
  text-shadow: 0px 0px 5px black;
  cursor: pointer;
}
.menuContent > li > a {
  color: white;
  text-decoration: none;
}

.menuIcon {
  margin-top: -4px;
  margin-left: 4px;
  font-size: 15px;
}
.menuDrop {
  /* border: solid red; */
  position: absolute;
  top: 80px;
  width: 10px;
  height: 100px;
  background-color: transparent;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  display: none;
}
#menudrop1 {
  width: 170px;
  height: 150px;
}
#menudrop2 {
  width: fit-content;
  height: 230px;
}
#menudrop3 {
  width: 130px;
  height: 30px;
}
.menuContent > li:hover .menuDrop {
  display: flex;
}
#menudrop2 > a {
  color: white;
  text-decoration: none;
}
.dropItems {
  /* border: solid black; */
  width: 100%;
  height: 20%;
  background-color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.dropItems:hover {
  background-color: white;
}
.dropItems:hover pre {
  color: #cc2228;
  text-shadow: none;
}
#dropItem2 {
  height: 25px;
  width: 200px;
}

#dropItem3 {
  height: 100%;
}
.dropItems > pre {
  font-size: 15px;
}
.mainMenu {
  margin-right: -200px;
  display: none;
  /* border: solid red; */
  width: 50px;
}
.hamIcon {
  /* border: solid yellow; */
  color: white;
  margin-right: 300px;
  font-size: 25px;
  width: fit-content;
}
.respMenu {
  /* border: solid red; */
  position: fixed;
  z-index: 9999;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: none;
  transition: 1s ease-in-out;
  background-color: #1d1d20;
  /* transform: translateX(500px); */
}
.respMenuScroll {
  height: 100%;
  width: 100%;
  margin-left: 0px;
  /* border: solid red; */
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(58, 58, 58);
}
::-webkit-scrollbar-track {
  width: 10px;
  background-color: gray;
}
.main {
  position: relative;
}
.cross {
  position: fixed;
  right: 120px;
  top: 0;
}

.respMenuScroll > ul {
  border-top: solid 1px black;
  background-color: #1d1d20;
  width: 100%;
}
.respMenuScroll > ul > h2 {
  color: white;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 150%;
  font-weight: lighter;
  margin-left: 10px;
}
.respMenuSpan > li > h4 {
  color: white;
  height: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 100%;
  font-weight: lighter;
  margin-left: 10px;
}
.respMenuScroll > ul > h2 > a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}
.respMenuSpan > li {
  list-style: none;
  border-top: solid 1px black;
  background-color: #25252a;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.respMenuSpan > li > h4 {
  color: white;
  list-style: none;
  margin-left: 30px;
  font-size: 18px;
  font-weight: lighter;
}
.respMenuSpan li > h4 > a {
  text-decoration: none;
  color: white;
}
.respMenuIcon {
  /* border: solid white; */
  width: 30px;
  height: 30%;
  font-size: 100%;
}
.respMenuSpan {
  /* border: solid; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.respMenuScroll > ul > span {
  display: none;
}
.respDropIcon {
  /* border: solid; */
  margin-left: 50px;
}
.respMenuGap {
  /* border: solid red; */
  height: 225px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .navContainer {
    /* border: solid red; */
    height: 80px;
    width: 100%;
  }
  .navContainer1 {
    /* border: solid red; */
    height: 80px;
    width: 100%;
  }
  .logo {
    /* border: green solid; */
    width: 80px;
  }
  .menuContent {
    display: none;
  }
  .mainMenu {
    display: flex;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .logo {
    /* border: green solid; */
    width: 90px;
  }

  .menuContent {
    display: none;
  }
  .mainMenu {
    display: flex;
  }
  .mainMenu {
    margin-left: 200px;
    width: 50px;
    /* border: solid red; */
  }
}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .logo {
    /* border: green solid; */
    width: 90px;
  }
  .menuContainer {
    /* border: solid red; */
    width: 100%;
  }
  .menuContent > li {
    /* border: solid blueviolet; */
    font-size: 15px;
    text-align: center;
  }
}
