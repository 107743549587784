.hairTrim {
  /* border: solid red; */
  width: 100%;
}
.hairTrimContent {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px;
}
.hairTrimImg {
  /* border: solid rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.hairTrimImg > img {
  width: 70%;
  border-radius: 10px;
}
.hairTrimServImg > img {
  width: 60%;
  height: 60%;
  border-radius: 10px;
  object-fit: cover;
  /* object-position: -150px; */
}
.hairTrimServImg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hairTrimHead {
  /* border: solid blue; */
  width: 90%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.hairTrimHead > h1 {
  font-size: 30px;
  color: var(--customColor);
  text-align: center;
}
.hairTrimHead > h2 {
  font-size: 30px;
  color: var(--customColor);
  text-align: center;
}
.hairTrimHead > h3 {
  font-size: 25px;
  color: var(--customColor);
  text-align: start;
  width: 100%;
}
.hairTrimHead > p {
  width: 100%;
  font-size: 20px;
  text-align: justify;
}
.hairTrimHead > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.hairTrimHeadContent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.hairTrimHeadContent > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.hairTrimHeadContent > p > span {
  color: var(--customColor);
  font-size: 20px;
  font-weight: bold;
}
.hairTrimHeadContent > h3 {
  font-size: 20px;
  width: 100%;
  color: var(--customColor);
  text-align: start;
}
.hairTrimHeadContent > ul {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.hairTrimHeadContent > ul > li {
  font-size: 20px;
  margin: 10px;
}
.hairTrimHeadContent > ul > li > span {
  color: var(--customColor);
  font-weight: bold;
}
.hairTrimFaqContainer {
  /* border: solid green; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.hairTrimFaqContent {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}
.hairTrimFaqContent > h3 {
  font-size: 20;
  font-weight: bold;
  color: var(--customColor);
}
.hairTrimFaqContent > p {
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hairTrimHead {
    /* border: solid blue; */
    width: 100%;
    padding: 10px;
  }
  .hairTrimImg > img {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .hairTrimServImg > img {
    /* border: solid; */
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .hairTrimServImg {
    /* border: solid; */
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
